import React from 'react';
import { useTranslation } from 'react-i18next';

import { Space } from '../../types';
import { formatCurrency } from '../../utils/currency';
import { config } from '../../utils/config';
import { AspectRatioOption } from '../../utils/image/helper';

import { BoldText, Subtitle, Text } from '../../atoms/typography';
import { Team } from '../../atoms/icons';

import { Carousel } from '../carousel';

import styles from './SpaceOverviewCell.module.css';

export const SpaceOverviewCell: React.FC<SpaceOverviewCellProps> = ({ space, onClick }) => {
	const { t } = useTranslation();

	return (
		<div key={space.id} onClick={onClick} className={styles.container} role="button" data-testid="space-cell">
			<Carousel
				images={space.images.map((url) => ({ src: url, alt: space.title }))}
				aspectRatio={AspectRatioOption.WIDE}
			/>
			<div className={styles.textContainer}>
				<div>
					<Subtitle className={styles.subtitle}>{space.title}</Subtitle>
					{space.from_price.total_price > 0 && (
				  <>
  					<BoldText>
	  					{formatCurrency(config.show_tax ? space.from_price.total_price : space.from_price.price)}
		  			</BoldText>
			  		<Text>
				  		{`${t('price_period.' + space.from_price.type)} ${t(
					  		config.show_tax ? 'incl_tax' : 'excl_tax'
						  )}`}
					  </Text>
					</>
					)}
				</div>

				<div className={styles.capacityContainer}>
					<Team className={styles.icon} />
					<BoldText className={styles.capacity}>
						{Math.max(space.capacity.min, 1) + ' - ' + space.capacity.max}
					</BoldText>
				</div>
			</div>
		</div>
	);
};

interface SpaceOverviewCellProps {
	space: Space;
	onClick?: () => void;
}
