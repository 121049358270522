import { useCallback } from 'react';

import { useMessage } from './useMessage';
import { logger } from '../utils/logger';

export const useAppEvents = () => {
	const [createMessage] = useMessage();
	const createEvent = useCallback((type, payload = null) => {
		logger('new event', { type, payload });
		createMessage({ type: 'event', value: { type, payload } });
	}, []);

	return [createEvent];
};
