import { SpaceCategory, PriceUnit, CapacityCategory, Period } from './../types';

export default {
	apply: 'Apply',
	welcome: 'Welcome to Venuesuite',
	persons: 'person',
	persons_plural: 'persons',
	eventType: 'Activity',
	personsAmount: 'Number of persons',
	date: 'Date',
	startTime: 'Start time',
	endTime: 'End time',
	company: 'Company',
	time_slots: {
		morning: 'Morning',
		afternoon: 'Afternoon',
		full_day: 'Full day',
	},
	per_person: 'per person',
	tax: 'Tax',
	excl_tax: 'excl. tax',
	incl_tax: 'incl. tax',
	heading_subtitle: '{{count}} person · {{startDate, MMM D}} · {{startDate, HH:mm}}-{{endDate, HH:mm}}',
	heading_subtitle_plural: '{{count}} persons · {{startDate, MMM D}} · {{startDate, HH:mm}}-{{endDate, HH:mm}}',
	meeting_type: {
		[SpaceCategory.Meeting]: 'Meeting',
		[SpaceCategory.Training]: 'Training',
		[SpaceCategory.Workshop]: 'Workshop',
		[SpaceCategory.Reception]: 'Reception',
		[SpaceCategory.Dinner]: 'Dinner',
		[SpaceCategory.Other]: 'Other',
	},
	price_unit: {
		[PriceUnit.PerPerson]: '/ person',
		[PriceUnit.PerDayPart]: '/ daypart',
		[PriceUnit.PerHourPerPerson]: '/ hour (p.p.)',
		[PriceUnit.Total]: '/ total',
	},
	price_period: {
		[Period.DayPart]: '/ daypart',
		perDaypart: '/ daypart',
		[Period.Day]: '/ day',
		perDay: '/ day',
		[Period.Hour]: '/ hour',
		perHour: '/ hour',
		[Period.Package]: '/ person',
	},
	extraCategory: {
		equipment: 'Equipment',
		food: 'Food',
		beverages: 'Beverages',
	},
	extrasTitle: 'Extras on request',
	extrasDescription: 'You can easily request the following items online after you have completed your reservation',
	free: 'Included',
	spaces: 'Select space',
	including: 'Including - {{equipment}}',
	capacity: '{{min}} - {{max}} persons',
	comments_questions: 'Comments & questions (optional)',
	email: 'E-mail',
	email_help: 'The confirmation email will be sent to this address',
	reservation_explanation: `1. 
We will confirm your request as quickly as possible as optional reservation (usually within a few hours).

2. 
Via email you will get access to your optional reservation. Via the chat we will be in direct contact for any questions or adjustments to your reservation.

3.
With one simple click you can definitely confirm your reservation or cancel free of charge.`,
	book: 'Request reservation',
	bookNow: 'Online reservation',
	priceAvailability: 'Show available spaces',
	reserve: 'show price summary {{price}}',
	space_included: 'Space Included',
	onlySpace: "Space + extra's on request",
	onlySpaceDescription: 'Catering and equipment can be easily requested after reservation',
	packages: 'Packages',
	catering: 'Catering',
	equipment: 'Equipment',
	packagesUnavailable: 'Unfortunately there are no packages available for this space',
	cateringUnavailable: 'Unfortunately there is no catering available for this space',
	equipmentUnavailable: 'Unfortunately there is no equipment available for this space',
	find_spaces: 'Finding available spaces',
	find_packages: 'Finding matching packages',
	show_spaces: 'Show available spaces',
	aboutSpace: 'About this space',
	reservationType: 'Select package',
	summary: 'Your {{spaceType}}',
	terms: 'What happens when I click "request reservation"?',
	errors: {
		validation: {
			persons: 'Please enter a number',
			required: 'This field is required',
			email: 'Please enter a valid email address',
			name: 'Please enter your full name',
			company: 'Please enter your company name',
			telephone: 'Please enter a valid phone number',
			time: 'The start time must before the end time',
			after24: 'The start time must be at least 24 hours from now',
		},
		network: {
			fetch_spaces: 'Something went wrong while trying to load the available spaces. Please try again.',
			fetch_space: 'Something went wrong while trying to load the space. Please try again.',
			fetch_invoice: 'Something went wrong while trying to load the invoice. Please try again.',
			book: 'Something went wrong while trying to make the reservation. Please try again.',
			space: 'Something went wrong while trying to make the reservation. Please try again.',
		},
	},
	success: {
		title: 'Thank you for your request!',
		description: 'We have sent an email to <1>{{email}}</1> with a link to activate your account to:',
		checkmark: ['Adjust the reservation', 'Chat with an account manager', 'Stay informed of changes'],
		check_spam: "Didn't receive an email? Check your spam folder.",
	},
	done: 'Done',
	time_range: '{{startDate, HH:mm}} until {{endDate, HH:mm}}',
	noSpaces: 'Sorry we can’t find any spaces matching your search query.',
	noSpacesClick: 'Please press here to change them and try again.',
	subtotal: 'Subtotal',
	vat: 'VAT {{percentage}}%',
	total: 'Total',
	accomodation_type: {
		double: 'Double room',
		single: 'Single room',
	},
	many_accomodations: 'More than 20',
	accomodation_subtitle: 'Hotel rooms',
	accomodation_text: 'The following rooms are available for an overnight stay',
	equipmentOptions: {
		TV_SCREEN: 'TV Screen',
		PROJECTOR: 'Projector',
		PROJECTION_AND_SCREEN: 'Projector & Screen',
		MONITOR: 'Monitor',
		BEAMER: 'Beamer',
		SPEAKERS: 'Speakers',
		SOUND_SYSTEM: 'Sound System',
		MICROPHONE: 'Microphone',
		LASER_POINTER: 'Laser Pointer',
		AUDIO_ENGINEER: 'Audio Engineer',
		LAPTOP: 'Laptop',
		EXTRA_CHAIRS: 'Extra Chairs',
		EXTRA_TABLES: 'Extra Tables',
		LIGHTING: 'Lighting',
		SPEAKERS_DESK: 'Speakers Desk',
		WARDROBE: 'Wardrobe',
		VIDEO_CAMERA: 'Video Camera',
		CONFERENCE_PHONE: 'Conference Phone',
		PENS_AND_PAPER: 'Pens & Paper',
		WHITEBOARD: 'Whiteboard',
		FLIPCHART: 'Flipchart',
		HEADSET: 'Headset',
		STAGE: 'Stage',
		DAYLIGHT: 'Daylight',
	},
	setupOptions: {
		[CapacityCategory.Cabaret]: 'Cabaret',
		[CapacityCategory.Carre]: 'Carré',
		[CapacityCategory.Circle]: 'Circle',
		[CapacityCategory.Exam]: 'Exams',
		[CapacityCategory.School]: 'School',
		[CapacityCategory.Theater]: 'Theater',
		[CapacityCategory['U-Shape']]: 'U-shape',
		u_shape: 'U-shape',
		[CapacityCategory.Standing]: 'Standing',
		[CapacityCategory.Party]: 'Party',
		[CapacityCategory.Dinner]: 'Dinner',
		[CapacityCategory.Boardroom]: 'Boardroom',
	},
	features: {
		lunch: 'Lunch',
		softdrinks: 'Softdrinks',
		alcoholic: 'Alcohol',
		water: 'Water',
		coffee_and_tea: 'Coffee and tea',
		dinner: 'Dinner',
		snacks: 'Sancks',
	},
	setupTitle: 'Capacity',
	full_name: 'Full name',
	telephone: 'Phone number',
	contact_details: 'Contact details',
	contact_details_help: 'This will allow us to be in direct contact with you.',
	loading: 'Loading',
	partial_availability: 'Partial availability',
};
