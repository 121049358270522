import React from 'react';
import { useDeviceDetect } from '../../hooks/useDeviceDetect';
import { Text } from '../typography';
import styles from './Tooltip.module.css';

export const Tooltip: React.FC<ToolTipProps> = ({ listValue, visible, title, description }) => {
	const device = useDeviceDetect();

	return (
		<div
			className={`${styles.container} ${!device.isMobile ? styles.delay_tooltip : ''} ${
				!visible ? styles.hidden : ''
			}`}
		>
			{listValue ? (
				listValue.map((feature) => (
					<div className={styles.divider}>
						<Text className={styles.title}>{feature.title}</Text>
						<Text className={styles.description}>{feature.description}</Text>
					</div>
				))
			) : (
				<div className={styles.divider}>
					<Text className={styles.title}>{title}</Text>
					<Text className={styles.description}>{description}</Text>
				</div>
			)}
		</div>
	);
};

interface ToolTipProps {
	listValue?: any[];
	visible: boolean;
	title: string;
	description: string;
}
