import React from 'react';
import classNames from 'classnames';

import styles from './Radio.module.css';

import { CheckCircle, UncheckCircle } from '../../atoms/icons';

export const Radio: React.FC<RadioProps> = ({ type = 'circle', selected, label, disabled, onClick, ...props }) => {
	return (
		<div
			className={classNames(styles.wrapper, { [styles.disabled]: disabled })}
			onClick={(e) => {
				if (!disabled) {
					onClick?.(e);
				}
			}}
			{...props}
		>
			<label>
				<div
					className={classNames(styles.radio, {
						[styles.selected]: selected,
						[styles.withLabel]: Boolean(label),
					})}
				>
					{type === 'check' && <CheckCircle className={classNames(styles.icon, styles.check)} />}
					{type === 'circle' && <div className={classNames(styles.icon, styles.circle)} />}
					<UncheckCircle className={styles.icon} />
				</div>
				{label}
			</label>
		</div>
	);
};

interface RadioProps {
	selected: boolean;
	type?: 'check' | 'circle';
	label?: string;
	disabled?: boolean;
	onClick?: (e: React.MouseEvent) => void;
}
