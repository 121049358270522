import React from 'react';
import styles from './SpaceSkeleton.module.css';

export const SpaceSkeleton: React.FC = () => {
	return (
		<div className={styles.container}>
			<div className={styles.image} />
			<div className={styles.bars}>
				<div className={styles.topBar} />
				<div className={styles.bottomBar} />
			</div>
		</div>
	);
};
