import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import styles from './DetailsScreen.module.css';
import { showError } from '../../atoms/error';
import { Button } from '../../atoms/button';
import { Screen } from '../../atoms/screen';
import { SectionTitle, Text, Title, Subtitle, BoldText } from '../../atoms/typography';

import { Carousel } from '../../molecules/carousel';
import { FiltersOverview } from '../../molecules/filters-overview';
import { ArrangementCell } from '../../molecules/arrangement-cell';
import { Accordion } from '../../molecules/accordion';
import { SetupOverview } from '../../molecules/setup-overview';

import {
	Catering,
	Arrangement,
	Equipment,
	Space,
	Invoice,
	Accomodation,
	PriceUnit,
	SelectedAccomodation,
} from '../../types';
import { formatCurrency } from '../../utils/currency';
import { HttpService } from '../../utils/http';
import { AccomodationCell } from '../../molecules/accomodation-cell';
import { Team } from '../../atoms/icons';
import { config } from '../../utils/config';
import { useFilters } from '../../hooks/useFilters';
import { LoadingIndicator } from '../../atoms/loading-indicator';
import { Package } from '../../atoms/icons';
import Skeleton from '../../molecules/package-skeleton';

const httpService = new HttpService();

export const DetailsScreen: React.FC<DetailsScreenProps> = ({
	onBack,
	onBook,
	space,
	onArrangementPick,
	selectedAccomodations,
	onSelectedAccomodationsChange,
	selectedArrangements,
}) => {
	const { t } = useTranslation();

	/**
	 * If no pricing parts are defined the API returns an empty array instead of an PricePerDayPart object.
	 * In which case the space can only be booked in combination with an arrangement.
	 * Otherwise an arrangement is optional.
	 */

	const [accordionSection, setAccordionSection] = useState<number | null>(null);
	const [availableArrangements, setAvailableArrangements] = useState<Arrangement[]>([]);
	const [availableAccomodations, setAvailableAccomodations] = useState<Accomodation[]>([]);
	const [catering, setCatering] = useState<Catering[]>([]);
	const [equipment, setEquipment] = useState<Equipment[]>([]);
	const [invoice, setInvoice] = useState<Invoice>();
	const [currentSpace, setCurrentSpace] = useState<Space>(space);
	const [fetching, setFetching] = useState(false);
	const [loadingInvoice, setLoadingInvoice] = useState(false);
	const [showIndicator] = useDebounce(fetching, 1500, { leading: true });
	const { filters } = useFilters();
	const requiresArrangement = !(
		currentSpace.pricing.parts &&
		currentSpace.pricing.parts.morning &&
		currentSpace.pricing.parts.morning > 0
	);

	const getSpace = useCallback(
		async (filters) => {
			try {
				const data = await httpService.space(space.id, {
					guests: filters.persons,
					spaceCategory: filters.type,
					'period[start]': filters.startDate.format('YYYY-MM-DD HH:mm:00'),
					'period[end]': filters.endDate.format('YYYY-MM-DD HH:mm:00'),
					include: 'quote',
				});
				setCurrentSpace(data);
			} catch (e) {
				console.error(e);
			}
		},
		[space.id, filters]
	);

	// Get arrangements
	const getData = useCallback(
		async (filters) => {
			setFetching(true);

			try {
				const availableArrangements: Arrangement[] = [];
				const arrangementsSettings = config.arrangements;

				const allArrangements = await httpService.arrangements({
					guests: filters.persons,
					space_id: currentSpace.id,
					type: filters.type,
					'period[start]': filters.startDate.format('YYYY-MM-DD HH:mm:00'),
					'period[end]': filters.endDate.format('YYYY-MM-DD HH:mm:00'),
					include: 'quote',
				});

				if (arrangementsSettings[0] === '*') {
					setAvailableArrangements(allArrangements);
				} else {
					allArrangements.forEach((arrangement) =>
						arrangementsSettings.forEach((arrangementId: any) => {
							if (arrangement.id === +arrangementId) {
								availableArrangements.push(arrangement);
							}
						})
					);
					setAvailableArrangements(availableArrangements);
				}

				const accommodationsSettings = config.accommodations;

				const accomodations = await httpService.accomodations({
					'period[start]': filters.startDate.format('YYYY-MM-DD HH:mm:00'),
					'period[end]': filters.endDate.format('YYYY-MM-DD HH:mm:00'),
				});

				if (accommodationsSettings[0] === '*') {
					setAvailableAccomodations(accomodations);
				} else {
					let availableAccommodations: Accomodation[] = accomodations.filter(
						(accommodation) => accommodationsSettings.indexOf(accommodation.id.toString()) !== -1
					);
					setAvailableAccomodations(availableAccommodations);
				}

				const catering = await httpService.catering({
					'period[start]': filters.startDate.format('YYYY-MM-DD HH:mm:00'),
					'period[end]': filters.endDate.format('YYYY-MM-DD HH:mm:00'),
				});
				setCatering(catering);

				const equipment = await httpService.equipment({
					'period[start]': filters.startDate.format('YYYY-MM-DD HH:mm:00'),
					'period[end]': filters.endDate.format('YYYY-MM-DD HH:mm:00'),
				});
				setEquipment(equipment);
			} catch (error) {
				console.debug(error);
				showError({ text: t('errors.network.space') });
				onBack();
			} finally {
				setFetching(false);
			}
		},
		[onArrangementPick, onBack, currentSpace.id, t]
	);

	useEffect(() => {
		// Filter all selected arrangements that are currently available
		const validSelection = selectedArrangements.filter(({ id }) =>
			availableArrangements.some((arr) => arr.id === id)
		);
		// If the valid selection is smaller than the current selection
		// it means some selected arrangements are no longer valid.
		const hasInvalidSelection = validSelection.length !== selectedArrangements.length;
		// Select first arrangement if an arrangement is required and no
		// selection has been made yet.
		if (validSelection.length === 0 && requiresArrangement && availableArrangements.length > 0) {
			onArrangementPick(availableArrangements.slice(0, 1), invoice!);
			// If the current selection is invalid, select the valid ones.
		} else if (hasInvalidSelection) {
			onArrangementPick(validSelection, invoice!);
		}
	}, [availableArrangements, requiresArrangement, selectedArrangements, invoice]);

	useEffect(() => {
		if (!availableArrangements.length) {
			getData(filters);
		}
	}, [availableArrangements.length, getData, filters]);

	// Get new invoice when new arrangement is selected

	const getInvoice = useCallback(async () => {
		setLoadingInvoice(true);
		try {
			const invoice = await httpService.invoice({
				name: '',
				space: {
					id: currentSpace.id,
				},
				period: {
					start: filters.startDate.format('YYYY-MM-DD HH:mm:00'),
					end: filters.endDate.format('YYYY-MM-DD HH:mm:00'),
				},
				guests: filters.persons,
				type: filters.type,
				packages: [...selectedArrangements],
				catering: [],
				equipment: [],
				hotel: selectedAccomodations,
			});

			setInvoice(invoice);

			setLoadingInvoice(false);
		} catch (error) {
			console.debug(error);
			showError({ text: t('errors.network.fetch_space') });
			onBack();
		}
	}, [filters, onBack, selectedAccomodations, selectedArrangements, currentSpace, t]);

	// Get new invoice when new arrangement is selected
	useEffect(() => {
		getInvoice();
	}, [selectedArrangements, selectedAccomodations, currentSpace]);

	// Sort all equipment and catering extra's per category
	const extras = useMemo(() => {
		// Get a list of all the available categories
		const allExtras = [...equipment, ...catering];
		return allExtras
			.map((extra) => extra.category) // Get categories
			.filter((elem, index, self) => index === self.indexOf(elem)) // Remove duplicates
			.map((category) => ({
				name: category,
				items: allExtras.filter((item) => item.category === category),
			}));
	}, [catering, equipment]);

	const handleAccomodationQuantityChange = useCallback(
		(accomodation, quantity) => {
			let newAccomodations;

			if (quantity > 0) {
				/**
				 * Change quantity
				 */
				newAccomodations = [...selectedAccomodations];
				const match = newAccomodations.find((x) => x.id === accomodation.id);
				if (match) {
					match.quantity = quantity;
				} else {
					newAccomodations.push({ ...accomodation, quantity });
				}
			} else {
				/**
				 * Remove accomodation
				 */
				newAccomodations = selectedAccomodations.filter((x) => {
					return accomodation.id !== x.id;
				});
			}

			onSelectedAccomodationsChange(newAccomodations);
		},
		[onSelectedAccomodationsChange, selectedAccomodations]
	);

	const readableEquipment = currentSpace.equipment
		.map((item) => t(`equipmentOptions.${item.title.toUpperCase()}`))
		.filter((item) => !item.includes('equipmentOptions.'))
		.join(' · ');

	return (
		<Screen
			headerComponent={
				<FiltersOverview
					space={space}
					onBack={onBack}
					onChange={(value: any) => {
						getSpace(value);
						getData(value);
					}}
				/>
			}
			footerComponent={
				invoice && (
					<Button
						onClick={() => onBook(invoice)}
						disabled={loadingInvoice}
						title={
							showIndicator
								? t('loading')
								: t('reserve', {
										price: formatCurrency(
											((config.show_tax ? invoice.summary.total_price : invoice.summary.price) ||
												0) * 100
										),
								  })
						}
					/>
				)
			}
		>
			<Carousel
				images={currentSpace.images.map((image, index) => ({
					src: image,
					alt: `${currentSpace.title} #${index + 1}`,
				}))}
			/>
			<div className={styles.spaceDetailsContainer}>
				<div className={styles.spaceDetailsRow}>
					<div className={styles.spaceDetailsInformation}>
						<Subtitle className={styles.subtitle}>{currentSpace.title}</Subtitle>
					</div>
					<div className={styles.spaceDetailsGuests}>
						<Team className={styles.icon} />
						<BoldText className={styles.capacity}>
							{Math.max(currentSpace.capacity.min, 1)} - {currentSpace.capacity.max}
						</BoldText>
					</div>
				</div>
				<Text>{t('including', { equipment: readableEquipment })}</Text>
			</div>

			<div className={styles.titleContainer}>
				<Title>{t('reservationType')}</Title>
			</div>

			{/* If an arrangement is not required, we need to give the option to book the space without one */}
			{!requiresArrangement && !showIndicator && (
				<ArrangementCell
					title={t('onlySpace')}
					features={[
						{
							title: t('onlySpaceDescription'),
						},
					]}
					quote={config.show_tax ? currentSpace.quote.total_price : currentSpace.quote.price}
					price={{
						amount: currentSpace.from_price.price || 0,
						unit: PriceUnit.Total,
					}}
					selected={!selectedArrangements.length}
					onClick={() => {
						onArrangementPick([], invoice!);
					}}
				/>
			)}
			{showIndicator ? (
				<div className={styles.skeletonContainer}>
					{Array(3)
						.fill('')
						.map((_, index) => (
							<Skeleton key={index} />
						))}
				</div>
			) : (
				availableArrangements?.map((arrangement: Arrangement) => {
					const arrangementIncludesSpace =
						arrangement.space_always_included || arrangement.spaces.indexOf(currentSpace.id) !== -1;

					return (
						<ArrangementCell
							key={arrangement.id}
							title={arrangement.title}
							features={arrangement.products.catering}
							featuresList={arrangement.products.catering.reduce((r: any, a) => {
								if (a.component !== undefined) {
									r[a.component] = [...(r[a.component] || []), a];
								}
								return r;
							}, {})}
							price={{
								amount: config.show_tax ? arrangement.pricing.included : arrangement.pricing.excluded,
								unit: arrangement.pricing.unit,
								isExtra: !arrangementIncludesSpace,
							}}
							quote={config.show_tax ? arrangement.quote.total_price : arrangement.quote.price}
							selected={selectedArrangements[0]?.id === arrangement.id}
							onClick={() => {
								onArrangementPick([arrangement], invoice!);
							}}
						/>
					);
				})
			)}

			{!!availableAccomodations && !!availableAccomodations.length && (
				<>
					<div className={styles.textContainer}>
						<div className={styles.descriptionBlock}>
							<SectionTitle>{t('accomodation_subtitle')}</SectionTitle>
							<div className={styles.spacer} />
							<Text>{t('accomodation_text')}</Text>
						</div>
					</div>
					<div>
						{availableAccomodations.map((accomodation: Accomodation) => {
							const matchingSelectedAccomodation = selectedAccomodations.find(
								(x) => x.id === accomodation.id
							);

							const quantity = matchingSelectedAccomodation?.quantity || 0;

							return (
								<AccomodationCell
									key={accomodation.id}
									accomodation={accomodation}
									defaultQuantity={filters.persons}
									quantity={quantity}
									selected={quantity > 0}
									onQuantityChange={(quantity) => {
										handleAccomodationQuantityChange(accomodation, quantity);
									}}
								/>
							);
						})}
					</div>
				</>
			)}

			{extras.length > 0 && (
				<div className={styles.accordionContainer}>
					<SectionTitle>{t('extrasTitle')}</SectionTitle>
					<div className={styles.spacer} />
					<Text>{t('extrasDescription')}</Text>
					<div className={styles.bigSpacer} />
					{extras.map((category, index) => (
						<Accordion
							key={category.name || index}
							title={t('extraCategory.' + category.name)}
							items={category.items}
							selected={accordionSection === index}
							onClick={() => setAccordionSection(accordionSection === index ? null : index)}
						/>
					))}
				</div>
			)}

			{currentSpace.setups.length > 0 && (
				<div className={styles.spaceDetailsContainer}>
					<div className={styles.bigSpacer} />
					<SectionTitle>{t('setupTitle')}</SectionTitle>
					<div className={styles.bigSpacer} />
					<SetupOverview space={currentSpace} />
				</div>
			)}

			{!!currentSpace.description && (
				<div className={styles.textContainer}>
					<SectionTitle>{t('aboutSpace')}</SectionTitle>
					<div className={styles.spacer} />
					<Text>{currentSpace.description}</Text>
				</div>
			)}

			<LoadingIndicator withFooter isActive={showIndicator} icon={<Package />} text={t('find_packages')} />
		</Screen>
	);
};

interface DetailsScreenProps {
	onBack(): void;
	onBook(invoice: Invoice): void;
	space: Space;
	onArrangementPick(data: Arrangement[], invoice: Invoice): void;
	onCateringPick(data: Catering[]): void;
	onEquipmentPick(data: Equipment[]): void;
	selectedAccomodations: SelectedAccomodation[];
	onSelectedAccomodationsChange(accomodations: SelectedAccomodation[]): void;
	selectedArrangements: Arrangement[];
}
