import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './FiltersOverview.module.css';
import { Edit } from '../../atoms/icons';
import { Text, BoldText } from '../../atoms/typography';
import { useFilters } from '../../hooks/useFilters';
import { useAppEvents } from '../../hooks/useAppEvents';
import { NavHeader } from '../nav-header';
import { FiltersForm } from './FiltersForm';
import { Space } from '../../types';

export const FiltersOverview: React.FC<FiltersOverviewProps> = ({ onBack, onChange, space }) => {
	const { t } = useTranslation();
	const { filters } = useFilters();
	const [createEvent] = useAppEvents();

	return (
		<NavHeader
			onBack={onBack}
			summary={({ scrollToTop }) => (
				<div className={styles.summary} onClick={scrollToTop}>
					<div>
						<BoldText className={styles.subtitle}>{t(`meeting_type.${filters.type}`)}</BoldText>
						<Text style={{ whiteSpace: 'nowrap' }}>
							{t('heading_subtitle', {
								count: filters.persons,
								startDate: filters.startDate.toDate(),
								endDate: filters.endDate.toDate(),
							})}
						</Text>
					</div>
					<div>
						<button className={styles.editIcon}>
							<Edit />
						</button>
					</div>
				</div>
			)}
		>
			<FiltersForm
				space={space}
				onChange={(values) => {
					createEvent('search_widget', {
						...values,
						startDate: values.startDate.toISOString(),
						endDate: values.endDate.toISOString(),
					});
					onChange?.(values);
				}}
			/>
		</NavHeader>
	);
};

interface FiltersOverviewProps {
	onBack(): void;
	onChange?(value: any): void;
	space?: Space;
}
