import React from 'react';
import classNames from 'classnames';

import styles from './Modal.module.css';

export const Modal: React.FC<ModalProps> = ({ children, visible }) => {
	return <div className={classNames(styles.modal, { [styles.visible]: visible })}>{children}</div>;
};

interface ModalProps {
	visible: boolean;
}
