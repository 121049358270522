/**
 * Formats a number or a string of numbers to a currency string. If the function can't handle the input the input is returned.
 */

import { config } from './/config';

export const formatCurrency = (amount: string | number) => {
	const formatter = new Intl.NumberFormat(config.language, { style: 'currency', currency: 'EUR' });

	if (typeof amount === 'string') {
		const result = Number.parseFloat(amount);

		if (Number.isNaN(result)) {
			return amount;
		}

		return formatter.format(result / 100);
	}

	return formatter.format(amount / 100);
};
