import { useMemo } from 'react';

export const useDeviceDetect = () => {
	const isMobile = useMemo(() => {
		const userAgent = typeof window.navigator === 'undefined' ? '' : navigator.userAgent;
		return Boolean(userAgent.match(/Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i));
	}, []);

	return { isMobile };
};
