import React, { createContext, useCallback, useState } from 'react';
import { positionValues } from 'react-custom-scrollbars';

const defaultPositionValues = {
	top: 0,
	left: 0,
	scrollTop: 0,
	scrollHeight: 0,
	scrollLeft: 0,
	scrollWidth: 0,
	clientHeight: 0,
	clientWidth: 0,
};

export const ScrollContext = createContext<{
	scrollValues: positionValues;
	setScrollValues: (values: positionValues) => void;
	resetScrollValues: () => void;
	setRef: (ref: any) => void;
	scrollToTop: () => void;
}>({
	scrollValues: defaultPositionValues,
	scrollToTop: () => {},
	setScrollValues: () => {},
	resetScrollValues: () => {},
	setRef: () => {},
});

export const ScrollProvider: React.FC<{ children: any }> = ({ children }) => {
	const [scrollValues, setScrollValues] = useState<positionValues>(defaultPositionValues);
	const [ref, setRef] = useState<any>();
	const resetScrollValues = useCallback(() => setScrollValues(defaultPositionValues), []);
	const scrollToTop = useCallback(() => {
		ref.view.scroll({ top: 0, behavior: 'smooth' });
	}, [ref]);

	return (
		<ScrollContext.Provider
			value={{
				scrollValues,
				setScrollValues,
				resetScrollValues,
				scrollToTop,
				setRef,
			}}
		>
			{children}
		</ScrollContext.Provider>
	);
};
