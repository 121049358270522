import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './Input.module.css';

export const Input = React.forwardRef(
	({ borderless, containerStyles, value: defaultValue, type, ...props }: InputProps, ref) => {
		const [value, setValue] = useState(defaultValue);

		useEffect(() => {
			setValue(defaultValue);
		}, [defaultValue]);

		return (
			<div className={classNames(styles.container, { [styles.borderless]: borderless })} style={containerStyles}>
				{!!props.icon && <div className={styles.iconContainer}>{props.icon}</div>}
				<input
					{...props}
					type={type}
					value={value}
					ref={ref as any}
					onChange={(e) => {
						const { value: newValue } = e.target;

						if (
							type === 'number' &&
							newValue !== '' &&
							((props.min && newValue < props.min) ||
								(props.max && newValue > props.max) ||
								!newValue.match(/^-?\d+$/))
						) {
							return;
						}

						setValue(newValue);

						if (type !== 'number' || newValue !== '') {
							props.onChange?.(e);
						}
					}}
					className={styles.input}
				/>
				{!!props.postfix && <div className={styles.postfix}>{props.postfix}</div>}
			</div>
		);
	}
);

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
	icon?: React.ReactNode;
	postfix?: React.ReactNode;
	onChange?: (event: any) => void;
	borderless?: boolean;
	containerStyles?: React.CSSProperties;
}
