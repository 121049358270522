import React from 'react';

export const Spinner: React.FC<React.SVGProps<SVGSVGElement>> = ({ color = '#707D83', ...props }) => {
	return (
		<svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<g stroke="none" strokeWidth="1.33333" fill="none" fillRule="evenodd">
				<g fill={color} fillRule="nonzero">
					<path d="M20,0.682492413 C20,1.20718999 20.0996749,1.70844997 20.2810019,2.1681277 C9.99072701,3.94499684 2.16,12.9707718 2.16,23.8378685 C2.16,35.9812524 11.9381,45.8254082 24,45.8254082 C36.0619,45.8254082 45.84,35.9812524 45.84,23.8378685 C45.84,12.9707718 38.009273,3.94499684 27.718786,2.16779416 C27.9003251,1.70844997 28,1.20718999 28,0.682492413 C28,0.45050883 27.9805158,0.223106765 27.9431051,0.00185438863 L28.3168713,0.0655432871 C39.5114061,2.11255622 48,11.9772371 48,23.8378685 C48,37.182211 37.2548,48 24,48 C10.7452,48 0,37.182211 0,23.8378685 C0,12.0736926 8.35108992,2.27313537 19.4105533,0.117032654 C19.6255439,0.075119059 19.841558,0.0360942637 20.0585541,6.8278716e-15 C20.0196035,0.221715969 20,0.4497994 20,0.682492413 Z" />
				</g>
			</g>
		</svg>
	);
};

export default Spinner;
