import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Screen } from '../../atoms/screen';
import styles from './SuccessScreen.module.css';
import { Title, Text, EmphasizedText } from '../../atoms/typography';
import { Button } from '../../atoms/button';

export const SuccessScreenForVMS: React.FC<SuccessScreenProps> = ({ onClose, email }) => {
	const { t } = useTranslation();

	return (
		<Screen
			headerComponent={<div className={styles.header} />}
			footerComponent={<Button title={t('done')} onClick={onClose} />}
		>
			<div className={styles.container}>
				<Title className={styles.title}>{t('success.title')}</Title>
				<div className={styles.explanation}>
					<Text>
						<Trans i18nKey="success.description" values={{ email }}>
							We have sent a confirmation to <EmphasizedText>{email}</EmphasizedText> with a link to
							activate your account to:
						</Trans>
					</Text>
				</div>
				<div className={styles.email}>
					<Text>{t('success.next_step')}</Text>
				</div>
			</div>
		</Screen>
	);
};

interface SuccessScreenProps {
	onClose(): void;
	email: string;
}
