import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Typography.module.css';

export const Subtitle: React.FC<HTMLAttributes<HTMLHeadingElement>> = ({ className = '', children, ...otherProps }) => (
	<h3 {...otherProps} className={classNames(styles.subtitle, className)}>
		{children}
	</h3>
);
