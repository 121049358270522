import React from 'react';

export const Lunch: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="19" height="19" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M27.107 1.613c0.018-0.068 0.028-0.146 0.028-0.227s-0.010-0.159-0.029-0.233l0.001 0.006c-0.14-0.367-0.49-0.623-0.899-0.623-0.123 0-0.24 0.023-0.348 0.065l0.007-0.002c-0.253 0.093-6.347 2.52-6.347 17.040 0 0.53 0.43 0.96 0.96 0.96v0h4.787v11.68c0 0.53 0.43 0.96 0.96 0.96s0.96-0.43 0.96-0.96v0-28.427c-0.017-0.090-0.045-0.171-0.082-0.245l0.002 0.005zM21.413 16.68c0.16-8.133 2.36-11.76 3.813-13.333v13.333zM15.067 1.747c-0.53 0-0.96 0.43-0.96 0.96v0 8.453c-0.031 1.817-1.269 3.337-2.945 3.793l-0.028 0.007v-12.293c0-0.53-0.43-0.96-0.96-0.96s-0.96 0.43-0.96 0.96v0 12.253c-1.697-0.455-2.936-1.956-2.987-3.754l-0-0.006v-8.493c0-0.53-0.43-0.96-0.96-0.96s-0.96 0.43-0.96 0.96v0 8.493c0.007 2.888 2.104 5.284 4.859 5.755l0.035 0.005v13.333c0 0.53 0.43 0.96 0.96 0.96s0.96-0.43 0.96-0.96v0-13.333c2.783-0.482 4.874-2.876 4.88-5.759v-8.494c-0.021-0.504-0.428-0.906-0.932-0.92l-0.001-0z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default Lunch;
