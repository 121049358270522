import React, { createContext, Dispatch, SetStateAction, useMemo, useState } from 'react';
import dayjs from 'dayjs';

import { Filters } from '../types';
import { config } from '../utils/config';

export const FiltersContext = createContext<{ filters: Filters; setFilters: Dispatch<SetStateAction<Filters>> }>(
	{} as any
);

export const FiltersProvider: React.FC<{ children: any; initialValue?: Filters }> = ({ children, initialValue }) => {
	const initialFilters = useMemo<Filters>(() => {
		const dayAfterTomorrow = dayjs().add(2, 'day');
		// Convert "HH:mm-HH:mm" to [[HH, mm], [HH, mm]]
		const [start, end] = config.defaultDuration.split('-').map((s) => s.split(':').map(Number));

		return (
			initialValue ?? {
				persons: config.defaultGuests,
				type: config.eventType[0],
				startDate: dayAfterTomorrow.set('hour', start[0]).set('minute', start[1]),
				endDate: dayAfterTomorrow.set('hour', end[0]).set('minute', end[1]),
				duration: 0,
			}
		);
	}, [initialValue]);

	const [filters, setFilters] = useState<Filters>(initialFilters);

	return (
		<FiltersContext.Provider
			value={{
				filters: {
					...filters,
					duration: filters.endDate.diff(filters.startDate, 'hours', true),
				},
				setFilters,
			}}
		>
			{children}
		</FiltersContext.Provider>
	);
};
