import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import dayjs from 'dayjs';

import './i18n';
import App from './App';
import CSSVariables from './CSSVariables';
import { config } from './utils/config';

import { FiltersProvider } from './providers/FiltersProvider';
import { ScrollProvider } from './providers/ScrollProvider';
import { ModalProvider } from './providers/ModalProvider';

require('dayjs/locale/de');
require('dayjs/locale/nl');

dayjs.locale(config.language);

if (process.env.REACT_APP_SENTRY_DSN) {
	Sentry.init({
		dsn: process.env.REACT_APP_SENTRY_DSN,
		integrations: [new Integrations.BrowserTracing()],
		environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
	});
}

ReactDOM.render(
	<React.StrictMode>
		<ModalProvider>
			<ScrollProvider>
				<FiltersProvider>
					<CSSVariables />
					<App />
				</FiltersProvider>
			</ScrollProvider>
		</ModalProvider>
	</React.StrictMode>,
	document.getElementById('root')
);
