import React, { HTMLAttributes } from 'react';
import styles from './Typography.module.css';

export const EmphasizedText: React.FC<HTMLAttributes<HTMLParagraphElement>> = ({
	className = '',
	children,
	...otherProps
}) => (
	<span {...otherProps} className={`${styles.emphasizedText} ${className}`}>
		{children}
	</span>
);
