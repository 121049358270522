export interface Config {
	color: string;
	textColor: string;
	language: string;
	venueId: string | undefined;
	eventType: any | undefined;
	spaces: any | undefined;
	arrangements: any | undefined;
	defaultGuests: number;
	accommodations: any;
	defaultDuration: string;
	show_tax: boolean;
}

export const config: Config = window.location.search
	? JSON.parse(
			'{"' +
				decodeURI(window.location.search.substring(1))
					.replace(/"/g, '\\"')
					.replace(/&/g, '","')
					.replace(/=/g, '":"') +
				'"}'
	  )
	: {};

config.color = config.color ? decodeURIComponent(config.color) : '#f0465a';
config.textColor = config.textColor ? decodeURIComponent(config.textColor) : '#ffffff';
config.language = config.language ? decodeURIComponent(config.language) : 'en-US';
config.defaultGuests = config.defaultGuests ?? 10;
config.venueId = config.venueId || undefined;
config.eventType = config.eventType ? decodeURIComponent(config.eventType).split(',') : ['meeting'];
config.spaces = config.spaces ? decodeURIComponent(config.spaces).split(',') : ['*'];
config.arrangements = config.arrangements ? decodeURIComponent(config.arrangements).split(',') : ['*'];
config.accommodations = config.accommodations ? decodeURIComponent(config.accommodations).split(',') : ['*'];
config.defaultDuration = config.defaultDuration ? decodeURIComponent(config.defaultDuration) : '9:00-13:00';
config.show_tax = config.show_tax?.toString() === 'true';
