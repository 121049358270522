import React from 'react';

export const Package: React.FC<React.SVGProps<SVGSVGElement>> = ({ color, ...props }) => {
	return (
		<svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M10 6.26664L4 2.80664"
				stroke={color}
				strokeWidth="1.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M13 10.6667V5.33335C12.9998 5.09953 12.938 4.86989 12.821 4.66746C12.704 4.46503 12.5358 4.29692 12.3333 4.18002L7.66667 1.51335C7.46397 1.39633 7.23405 1.33472 7 1.33472C6.76595 1.33472 6.53603 1.39633 6.33333 1.51335L1.66667 4.18002C1.46418 4.29692 1.29599 4.46503 1.17897 4.66746C1.06196 4.86989 1.00024 5.09953 1 5.33335V10.6667C1.00024 10.9005 1.06196 11.1301 1.17897 11.3326C1.29599 11.535 1.46418 11.7031 1.66667 11.82L6.33333 14.4867C6.53603 14.6037 6.76595 14.6653 7 14.6653C7.23405 14.6653 7.46397 14.6037 7.66667 14.4867L12.3333 11.82C12.5358 11.7031 12.704 11.535 12.821 11.3326C12.938 11.1301 12.9998 10.9005 13 10.6667Z"
				stroke={color}
				strokeWidth="1.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1.17999 4.64001L6.99999 8.00668L12.82 4.64001"
				stroke={color}
				strokeWidth="1.33333"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path d="M7 14.72V8" stroke={color} strokeWidth="1.33333" strokeLinecap="round" strokeLinejoin="round" />
		</svg>
	);
};

export default Package;
