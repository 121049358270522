import React, { createContext, useCallback, useEffect, useState } from 'react';

import { Modal } from '../atoms/modal';

export const ModalContext = createContext<{
	showModal: (modal: ModalType) => void;
	updateModal: (modal: ModalType) => void;
}>({} as any);

const MODAL_SLIDE_ANIMATION_TIME = 500; //ms

export const ModalProvider: React.FC<{ children: any }> = ({ children }) => {
	const [modals, setModals] = useState<ModalType[]>([]);
	const [current, setCurrent] = useState<ModalType | null>(null);
	const showModal = useCallback((modal: ModalType) => {
		setModals((state) => [...state, modal]);
	}, []);
	const closeModal = useCallback(() => {
		setModals((state) => state.slice(0, -1));
	}, []);
	const updateModal = useCallback((modal: ModalType) => {
		if (modal.key === current?.key) {
			setCurrent({ ...current, ...modal });
		}
		setModals((modals) => modals.map((m) => (m.key === modal.key ? { ...m, ...modal } : m)));
	}, []);

	useEffect(() => {
		const handleDismiss = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				current?.closeWithEscape && closeModal();
			}
		};

		document.addEventListener('keydown', handleDismiss);

		return () => {
			document.removeEventListener('keydown', handleDismiss);
		};
	}, [closeModal, current]);

	useEffect(() => {
		if (modals.length === 0) {
			const timeout = setTimeout(() => {
				setCurrent(null);
			}, MODAL_SLIDE_ANIMATION_TIME);
			return () => {
				clearTimeout(timeout);
			};
		}
		setCurrent(modals[modals.length - 1]);
	}, [modals]);

	return (
		<ModalContext.Provider value={{ showModal, updateModal }}>
			<Modal visible={modals.length > 0}>
				{current &&
					React.cloneElement(current.element, {
						closeModal,
					})}
			</Modal>
			{children}
		</ModalContext.Provider>
	);
};

interface ModalType {
	key: string;
	element: React.ReactElement;
	closeWithEscape?: boolean;
}
