import * as Sentry from '@sentry/browser';

const serializeParams = (params: any) =>
	Object.keys(params)
		.reduce((acc, key) => `${acc}&${key}=${encodeURIComponent(params[key])}`, '')
		.substring(1);

export const request = async (url: string, { params, body, ...otherOptions }: any = { method: 'get' }) => {
	let concatenatedParams;
	if (params) {
		concatenatedParams = serializeParams(params);
	}
	const response = await fetch(`${process.env.REACT_APP_API_URL}/${url}${params ? `?${concatenatedParams}` : ''}`, {
		...otherOptions,
		body: body && Object.keys(body).length ? JSON.stringify(body) : undefined,
	});

	if (response.ok) {
		return response.json();
	}

	Sentry.captureEvent({
		breadcrumbs: [
			{
				category: 'http',
				message: `Failed request with URL: ${process.env.REACT_APP_API_URL}/${url}`,
				level: Sentry.Severity.Info,
				data: { params, body, ...otherOptions },
			},
		],
		message: '',
		extra: {
			httpStatus: response.status,
			httpMessage: response.statusText,
			responseData: await response.json(),
		},
		level: Sentry.Severity.Error,
	});

	throw new Error(`${response.status}: ${response.statusText}`);
};
