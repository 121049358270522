import React, { useRef } from 'react';
import classNames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { AspectRatioOption, getImageSource } from '../../utils/image/helper';
import { ChevronLeft, ChevronRight } from './../../atoms/icons';

import styles from './Carousel.module.css';

export const Carousel: React.FC<CarouselProps> = ({ images, aspectRatio = AspectRatioOption.SQUARE }) => {
	const imageWidth = Math.round(window.innerWidth * window.devicePixelRatio - 30);
	const sliderEl = useRef<any>(null);
	const imageClasses = classNames(styles.image, {
		[styles.square]: aspectRatio === AspectRatioOption.SQUARE,
		[styles.wide]: aspectRatio === AspectRatioOption.WIDE,
	});

	const settings = {
		ref: sliderEl,
		centerMode: true,
		centerPadding: 0,
		speed: 300,
		slidesToShow: 1,
		slidesToScroll: 1,
		className: styles.carouselSlider,
	};

	return (
		<div className={styles.container}>
			{images.length !== 1 && <div className={styles.controllsContainer} />}

			{images.length === 1 ? (
				<img
					className={imageClasses}
					src={getImageSource(images[0].src, aspectRatio, imageWidth)}
					alt={images[0].alt}
				/>
			) : (
				<div>
					<Slider {...settings}>
						{images.map((image: any, index: number) => (
							<div key={index} className={styles.slide}>
								<img
									className={imageClasses}
									src={getImageSource(image.src, aspectRatio, imageWidth)}
									alt={image.alt}
								/>
							</div>
						))}
					</Slider>
					<div className={styles.overlayContainer}>
						<button
							className={styles.previousButton}
							onClick={(e) => {
								e.stopPropagation();
								sliderEl.current?.slickPrev();
							}}
						>
							<ChevronLeft />
						</button>
						<button
							className={styles.nextButton}
							onClick={(e) => {
								e.stopPropagation();
								sliderEl.current?.slickNext();
							}}
						>
							<ChevronRight />
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

interface Image {
	src: string;
	alt: string;
}

interface CarouselProps {
	images: Image[];
	aspectRatio?: AspectRatioOption;
}
