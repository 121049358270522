import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import 'unfetch/polyfill';
import dayjs from 'dayjs';

import './styles/app.css';
import './styles/fonts.css';

import { WidgetFrame } from './atoms/widget-frame';

import { OverviewScreen } from './organisms/overview-screen';
import { DetailsScreen } from './organisms/details-screen';
import { BookScreen } from './organisms/book-screen';
import { SuccessScreen } from './organisms/success-screen';
import { FiltersScreen } from './organisms/filters-screen';

import { Screen, Space, Equipment, Catering, Arrangement, SelectedAccomodation } from './types';

import { useMessageListener } from './hooks/useMessageListener';
import { useMessage } from './hooks/useMessage';
import { useAppEvents } from './hooks/useAppEvents';
import { useFilters } from './hooks/useFilters';

function App() {
	const [screen, setScreen] = useState<Screen | null>(null);
	const [space, setSpace] = useState<Partial<Space> | null>();
	const { filters } = useFilters();
	const [arrangements, setArrangements] = useState<Arrangement[]>([]);
	const [accomodations, setAccomodations] = useState<SelectedAccomodation[]>([]);
	const [catering, setCatering] = useState<Catering[]>([]);
	const [equipment, setEquipment] = useState<Equipment[]>([]);
	const [email, setEmail] = useState('');
	const [isActive, setIsActive] = useState(true);
	const [sendMessage] = useMessage();
	const [createEvent] = useAppEvents();
	const openedAt = useRef(dayjs());

	useEffect(() => {
		// Reset all space-specific state when space changes
		if (!space) {
			setArrangements([]);
			setCatering([]);
			setEquipment([]);
			setAccomodations([]);
		}
	}, [space]);

	useMessageListener('transition', (message) => {
		const value = message.value as { state: 'open' | 'close'; source?: 'manual' | 'auto' };

		setIsActive(value.state === 'open');
		if (value.state === 'open') {
			setScreen(value.source === 'auto' ? Screen.Filters : Screen.Overview);
		} else if (value.state !== 'close') {
			setScreen(Screen.Overview);
  		setIsActive(true);
		}
	});

	useMessageListener('transition', (message) => {
		const value = message.value as { state: 'open' | 'close'; source?: 'manual' | 'auto' };

		if (value.state === 'open') {
			createEvent('open_widget');
		} else if (value.state === 'close') {
			createEvent('close_widget', { sessionDuration: dayjs().diff(openedAt.current, 'seconds') });
		}
	});

	const handleClose = useCallback(() => sendMessage({ type: 'close', value: 'close' }), [sendMessage]);

	const jsonFilters = useMemo(
		() => ({
			...filters,
			startDate: filters.startDate.toISOString(),
			endDate: filters.endDate.toISOString(),
		}),
		[filters]
	);

	const activeScreen = useMemo(() => {
		switch (screen) {
			case Screen.Filters:
				return (
					<FiltersScreen
						onBack={() => {
							handleClose();
						}}
						onNext={() => {
							setScreen(Screen.Overview);
						}}
					/>
				);
			case Screen.Overview:
				return (
					<OverviewScreen
						onBack={() => {
							handleClose();
						}}
						onSpacePick={(space: Space) => {
							setSpace(space);
							setScreen(Screen.Details);
							createEvent('select_space', {
								space,
								filters: jsonFilters,
							});
						}}
					/>
				);
			case Screen.Details:
				return (
					<DetailsScreen
						onBack={() => {
							setScreen(Screen.Overview);
							setSpace(null);
						}}
						onBook={(invoice) => {
							setScreen(Screen.Book);
							createEvent('show_summary', {
								space,
								arrangements,
								invoice,
								filters: jsonFilters,
							});
						}}
						space={space as Space}
						selectedArrangements={arrangements}
						onArrangementPick={(data, invoice) => {
							setArrangements(data);
							createEvent('select_package', { space, arrangements: data, filters: jsonFilters, invoice });
						}}
						onCateringPick={(data) => setCatering(data)}
						onEquipmentPick={(data) => setEquipment(data)}
						selectedAccomodations={accomodations}
						onSelectedAccomodationsChange={(accomodations) => setAccomodations(accomodations)}
					/>
				);
			case Screen.Book:
				return (
					<BookScreen
						onBack={() => {
							setScreen(Screen.Details);
						}}
						onBookSuccess={(email, invoice) => {
							setEmail(email);
							setScreen(Screen.Success);
							createEvent('request_booking', { space, arrangements, filters: jsonFilters, invoice });
						}}
						space={space as Space}
						arrangements={arrangements}
						catering={catering}
						equipment={equipment}
						accomodations={accomodations}
					/>
				);
			case Screen.Success:
				return (
					<SuccessScreen
						onClose={() => {
							handleClose();
							setScreen(Screen.Overview);
							createEvent('finish', null);
						}}
						email={email}
					/>
				);
			default:
				return null;
		}
	}, [screen, space, arrangements, catering, equipment, email, accomodations, handleClose]);

	return <WidgetFrame>{isActive && activeScreen}</WidgetFrame>;
}

export default App;
