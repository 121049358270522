import React, { SelectHTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Dropdown.module.css';
import { CaretDown } from '../icons';

export const Dropdown: React.FC<DropdownProps> = ({ value, options, borderless, ...otherProps }) => {
	return (
		<div className={classNames(styles.wrapper, { [styles.borderless]: borderless })}>
			<select {...otherProps} className={styles.select} value={value}>
				{options.map(({ label, value, disabled }) => (
					<option key={value} value={value} disabled={disabled}>
						{label}
					</option>
				))}
			</select>
			<span className={styles.caret}>
				<CaretDown />
			</span>
		</div>
	);
};

interface OptionProps {
	label: string;
	value: string;
	disabled?: boolean;
}

interface DropdownProps extends SelectHTMLAttributes<HTMLSelectElement> {
	options: OptionProps[];
	value?: string;
	borderless?: boolean;
}
