import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './NavHeader.module.css';
import { ChevronLeft } from '../../atoms/icons';
import { useScroll } from '../../hooks/useScroll';

const TRIGGER_OFFSET_IN = 120;
const TRIGGER_OFFSET_OUT = 80;

const BackButton = ({ onBack }: { onBack: () => void }) => (
	<div role="button" className={styles.button} onClick={onBack}>
		<ChevronLeft width={6} height={10} className={styles.caret} />
	</div>
);

export const NavHeader: React.FC<NavHeaderProps> = ({ onBack, sticky = false, children, summary }) => {
	const { scrollValues, scrollToTop } = useScroll();
	const [showSummary, setShowSummary] = useState(false);

	useEffect(() => {
		const triggerOffset = showSummary ? TRIGGER_OFFSET_OUT : TRIGGER_OFFSET_IN;
		setShowSummary(scrollValues.scrollTop > triggerOffset);
	}, [scrollValues.scrollTop, setShowSummary, showSummary]);

	return (
		<>
			<div className={styles.navHeader}>
				{!sticky && (
					<div className={styles.main}>
						<div className={styles.buttonContainer}>
							<BackButton onBack={onBack} />
						</div>
						<div className={styles.content}>{children}</div>
					</div>
				)}
			</div>
			<div
				className={classNames(styles.summary, {
					[styles.showSummary]: showSummary || sticky,
					[styles.noContent]: !summary,
				})}
			>
				<div className={styles.buttonContainer}>
					<BackButton onBack={onBack} />
				</div>
				<div className={styles.content}>{summary?.({ scrollToTop })}</div>
			</div>
		</>
	);
};

interface NavHeaderProps {
	children?: React.ReactNode;
	summary?({ scrollToTop }: { scrollToTop: () => void }): React.ReactNode;
	onBack(): void;
	sticky?: boolean;
}
