import React, { useCallback, useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs, { Dayjs } from 'dayjs';
import { registerLocale } from 'react-datepicker';
import { nl, de } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { Scrollbars } from 'react-custom-scrollbars';

import 'react-datepicker/dist/react-datepicker.css';

import { config } from '../../utils/config';
import { Button } from '../button';
import { Dropdown } from '../dropdown';
import { Close, ChevronLeft, ChevronRight } from '../icons';
import { Availability } from '../../types';

import styles from './TimeSlotInput.module.css';
import './react-datepicker-custom.css';

registerLocale('nl', nl);
registerLocale('de', de);

// Rounds a date to the nearest half hour
const roundDate = (date: Dayjs): Dayjs => date.set('minute', Math.round(date.minute() / 30) * 30);

const AFTERNOON_START = '12:30';
const EVENING_START = '18:00';

// Checks whether a time in the times array should be disabled
const isDisabled = (availability: any, index: number, times: string[], boundary: 'start' | 'end') => {
	if (availability?.availability_hour) {
		return !availability.availability_hour.includes(
			// Frontend hours are 0-padded, API are not.
			times[index].match(/^0/) ? times[index].slice(1) : times[index]
		);
	}
	return boundary === 'start'
		? (availability?.morning === 0 && index < times.indexOf(AFTERNOON_START)) ||
				(availability?.afternoon === 0 &&
					index >= times.indexOf(AFTERNOON_START) &&
					index < times.indexOf(EVENING_START)) ||
				(availability?.evening === 0 && index >= times.indexOf(EVENING_START))
		: (availability?.morning === 0 && index <= times.indexOf(AFTERNOON_START)) ||
				(availability?.afternoon === 0 &&
					index > times.indexOf(AFTERNOON_START) &&
					index <= times.indexOf(EVENING_START)) ||
				(availability?.evening === 0 && index > times.indexOf(EVENING_START));
};

const isValidValue = (availability: any, value: TimeSlotValue) => {
	const afternoon = Number(AFTERNOON_START.replace(':', ''));
	const evening = Number(EVENING_START.replace(':', ''));

	const start = Number(value.startDate.format('HHmm'));
	const end = Number(value.endDate.format('HHmm'));

	const validMorning = availability?.morning > 0 || start >= afternoon;
	const validAfternoon =
		availability?.afternoon > 0 || (start < afternoon && end <= afternoon) || (start >= evening && end > evening);
	const validEvening = availability?.evening > 0 || (start < evening && end <= evening);

	return (
		!availability ||
		(validMorning && validAfternoon && validEvening) ||
		(availability.availability_hour.includes(value.startDate.format('H:mm')) &&
			availability.availability_hour.includes(value.endDate.format('H:mm')))
	);
};

export const TimeSlotModal: React.FC<TimeSlotInputProps> = ({
	value,
	onChange,
	onMonthChange,
	onCloseModal,
	closeModal,
	availability,
}) => {
	const { t } = useTranslation();
	const [day, setDay] = useState(roundDate(value.startDate));
	const [hours, setHours] = useState([day.format('HH:mm'), roundDate(value.endDate).format('HH:mm')]);
	const now = useMemo(() => dayjs(), []);
	const startHours = useMemo(() => Number(hours[0].split(':')[0]), [hours]);
	const handleClose = useCallback(() => {
		onCloseModal?.();
		closeModal?.();
	}, []);
	const getValue = useCallback(() => {
		const time = hours.map((s) => s.split(':').map(Number));

		return {
			startDate: day.set('hour', time[0][0]).set('minute', time[0][1]),
			endDate: day.set('hour', time[1][0]).set('minute', time[1][1]),
		};
	}, [day, hours]);
	// {"YYYY-MM-DD": {"morning": 1, "afternoon": 1, ...}, ...}
	const availabilityObj: Record<string, any> = useMemo(
		() =>
			availability?.reduce(
				(acc, { date, availability, availability_hour }: any) => ({
					...acc,
					[date]: availability?.reduce((acc: any, value: any) => ({ ...acc, ...value })) ?? {
						availability_hour,
					},
				}),
				{}
			) ?? {},
		[availability]
	);
	const hasPartialAvailability = useMemo(
		() =>
			availability?.some(({ partial }) => partial) ||
			Object.values(availabilityObj)
				.map(Object.values)
				.reduce((acc, val) => acc || (val.includes(0) && val.includes(1)), false),
		[availabilityObj]
	);

	const availabilityForSelectedDay = availabilityObj[day.format('YYYY-MM-DD')];

	const startOptions = useMemo(() => {
		const times: string[] = [];

		// Every 30 minutes from 8:00 until 21:30
		for (let hours = 7; hours < 23; hours++) {
			if (hours < 10) {
				times.push(`0${hours}:00`, `0${hours}:30`);
			} else if (hours < 23) {
				times.push(`${hours}:00`, `${hours}:30`);
			}
		}

		return times.map((time, index) => {
			const disabled = isDisabled(availabilityForSelectedDay, index, times, 'start');

			return { label: time, value: time, disabled };
		});
	}, [availabilityForSelectedDay]);

	const endOptions = useMemo(() => {
		const times: string[] = [];

		// Every 30 minutes from 1 hour after start until 22:00
		for (let hours = startHours + 1; hours < 24; hours++) {
			if (hours < 10) {
				times.push(`0${hours}:00`, `0${hours}:30`);
			} else if (hours < 23) {
				times.push(`${hours}:00`, `${hours}:30`);
			} else {
				times.push('23:00');
			}
		}

		let hasDisabled = false;

		return times.map((time, index) => {
			const disabled = hasDisabled || isDisabled(availabilityForSelectedDay, index, times, 'end');
			hasDisabled = disabled;

			return { label: time, value: time, disabled };
		});
	}, [startHours, availabilityForSelectedDay]);

	useEffect(() => {
		if (hours[0] >= hours[1]) {
			setHours([hours[0], `${Number(hours[0].split(':')[0]) + 1}:${hours[1].split(':')[1]}`]);
		}
	}, [hours]);

	return (
		<div style={{ height: '100%' }}>
			<Scrollbars>
				<div className={styles.modalContent}>
					<div className={styles.closeButtonContainer}>
						<button className={styles.closeButton} onClick={handleClose}>
							<Close />
						</button>
					</div>
					<DatePicker
						inline
						locale={config.language}
						selected={day.toDate()}
						minDate={now.add(1, 'day').toDate()}
						maxDate={now.add(12, 'month').toDate()}
						dateFormat="dd-MM-yyyy"
						excludeDates={availability
							?.filter(({ availability, open }) => {
								const merged = availability?.reduce((acc, value) => ({ ...acc, ...value }), {} as any);
								return (
									!open ||
									(merged && merged.morning === 0 && merged.afternoon === 0 && merged.evening === 0)
								);
							})
							.map(({ date }) => dayjs(date).toDate())}
						onChange={(value) => {
							if (value) {
								setDay(dayjs(value));
							}
						}}
						onMonthChange={(date) => onMonthChange?.(dayjs(date))}
						calendarClassName="datepicker"
						renderDayContents={(dayOfMonth, date) => {
							// Count the availability parts. 0 means no availability,
							// 3 means full availability.
							const availabilitySum: number = Object.values<number>(
								availabilityObj[dayjs(date).format('YYYY-MM-DD')] ?? {}
							).reduce((a, b) => a + b, 0);
							const partial = availability?.find((item) => dayjs(date).isSame(item.date, 'day'))?.partial;

							return (
								<div className={styles.dayContents}>
									{dayjs(date).isAfter(now, 'day') &&
										(partial || (availabilitySum > 0 && availabilitySum < 3)) && (
											<div className={styles.partialAvailability}>
												<img src="/img/partial_availability.svg" alt="" />
											</div>
										)}
									<span>{`${dayOfMonth}`}</span>
								</div>
							);
						}}
						renderCustomHeader={({
							date,
							decreaseMonth,
							increaseMonth,
							prevMonthButtonDisabled,
							nextMonthButtonDisabled,
						}) => (
							<div className={styles.datePickerHeader}>
								<button
									className={styles.monthNavigateButton}
									onClick={decreaseMonth}
									disabled={prevMonthButtonDisabled}
								>
									<ChevronLeft />
								</button>
								<div className={styles.currentDate}>{dayjs(date).format('MMMM YYYY')}</div>
								<button
									className={styles.monthNavigateButton}
									onClick={increaseMonth}
									disabled={nextMonthButtonDisabled}
								>
									<ChevronRight />
								</button>
							</div>
						)}
					/>
					{hasPartialAvailability && (
						<div className={styles.legend}>
							<div className={styles.partialAvailability}>
								<img src="/img/partial_availability.svg" alt="" />
							</div>
							<span className={styles.legendLabel}>{t('partial_availability')}</span>
						</div>
					)}
					<div className={styles.time}>
						<Dropdown
							value={hours[0]}
							options={startOptions}
							onChange={(e) => {
								setHours([e.target.value, hours[1]]);
							}}
						/>
						<Dropdown
							value={hours[1]}
							options={endOptions}
							onChange={(e) => {
								setHours([hours[0], e.target.value]);
							}}
						/>
					</div>
					<div className={styles.applyButton}>
						<Button
							title={t('apply')}
							disabled={!isValidValue(availabilityForSelectedDay, getValue())}
							onClick={() => {
								onChange(getValue());
								handleClose();
							}}
						/>
					</div>
				</div>
			</Scrollbars>
		</div>
	);
};

interface TimeSlotValue {
	startDate: Dayjs;
	endDate: Dayjs;
}

interface TimeSlotInputProps {
	value: TimeSlotValue;
	onChange(timeSlot: TimeSlotValue): void;
	onMonthChange?(month: Dayjs): void;
	loading?: boolean;
	onCloseModal?: () => void;
	borderless?: boolean;
	closeModal?: () => void;
	availability?: Availability[];
}
