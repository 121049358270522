import React from 'react';

export const ChevronLeft: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="7" height="11" viewBox="0 0 7 11" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M5.57123 9.68542L1.34277 5.45697L5.57123 1.22852"
				stroke="#707D83"
				strokeWidth="1.40948"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default ChevronLeft;
