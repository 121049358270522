import React, { useEffect } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

import { useScroll } from '../../hooks/useScroll';
import styles from './Screen.module.css';

export const Screen: React.FC<ScreenProps> = ({ children, headerComponent, footerComponent, ...other }) => {
	const { setScrollValues, resetScrollValues, setRef } = useScroll();

	useEffect(() => {
		resetScrollValues();
	}, [resetScrollValues]);

	return (
		<div className={styles.container} {...other}>
			<Scrollbars autoHide onScrollFrame={setScrollValues} ref={(r) => setRef(r)}>
				<div className={styles.body}>
					{headerComponent && <div className={styles.header}>{headerComponent}</div>}
					{children}
				</div>
			</Scrollbars>
			{footerComponent && <div className={styles.footer}>{footerComponent}</div>}
		</div>
	);
};

interface ScreenProps {
	headerComponent?: React.ReactNode;
	footerComponent?: React.ReactNode;
}
