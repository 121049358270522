import React, { useState, useEffect, useCallback, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'use-debounce';

import { Space } from '../../types';
import { HttpService } from '../../utils/http';
import { config } from '../../utils/config';

import { useFilters } from '../../hooks/useFilters';
import { useMessage } from '../../hooks/useMessage';

import { FiltersOverview } from '../../molecules/filters-overview';
import SpaceOverviewCell from '../../molecules/space-overview-cell';
import Skeleton from '../../molecules/space-skeleton';

import { Screen } from '../../atoms/screen';
import { Calendar } from '../../atoms/icons';
import { LoadingIndicator } from '../../atoms/loading-indicator';
import { Title } from '../../atoms/typography';
import { showError } from '../../atoms/error';
import { PromoLink } from '../../atoms/promo-link';

import styles from './OverviewScreen.module.css';

const httpService = new HttpService();

export const OverviewScreen: React.FC<OverviewScreenProps> = ({ onBack, onSpacePick }) => {
	const [spaces, setSpaces] = useState<Space[]>();
	const [fetching, setFetching] = useState(false);
	const [showIndicator] = useDebounce(fetching, 1000, { leading: true });
	const { t } = useTranslation();
	const { filters } = useFilters();
	const [sendMessage] = useMessage();
	const getSpaces = useCallback(
		async (filters) => {
			setFetching(true);

			try {
				const spacesSettings = config.spaces;

				const allSpaces = await httpService.spaces({
					guests: filters.persons,
					spaceCategory: filters.type,
					'period[start]': filters.startDate.format('YYYY-MM-DD HH:mm:00'),
					'period[end]': filters.endDate.format('YYYY-MM-DD HH:mm:00'),
					include: 'quote',
				});

				if (spacesSettings[0] !== '*') {
					const spaces: Space[] = [];

					allSpaces.forEach((space) =>
						spacesSettings.forEach((spaceId: any) => {
							if (space.id === +spaceId) {
								spaces.push(space);
							}
						})
					);
					setSpaces(spaces);
				} else {
					setSpaces(allSpaces);
				}
			} catch (error) {
				showError({ text: t('errors.network.fetch_spaces') });
				onBack();
			} finally {
				setFetching(false);
			}
		},
		[t, onBack]
	);

	useLayoutEffect(() => {
		sendMessage({ type: 'screen', value: 'large' });
	}, []);

	useEffect(() => {
		getSpaces(filters);
		// eslint-disable-next-line
	}, []);

	return (
		<Screen
			headerComponent={<FiltersOverview onBack={onBack} onChange={(value: any) => getSpaces(value)} />}
			footerComponent={
				<PromoLink link="https://www.venuebookingwidget.com/?utm_source=Widget&utm_medium=ruimte-selectie" />
			}
		>
			<div className={styles.container}>
				{showIndicator ? (
					<>
						<div className={styles.title}>
							<Title>{t('spaces')}</Title>
						</div>
						{Array(5)
							.fill('')
							.map((_, index) => (
								<Skeleton key={index} />
							))}
					</>
				) : !spaces || spaces.length < 1 ? (
					<div className={styles.emptyStateContainer}>
						<div className={styles.title}>
							<Title>{t('noSpaces')}</Title>
						</div>
					</div>
				) : (
					<>
						<div className={styles.title}>
							<Title>{t('spaces')}</Title>
						</div>
						{spaces.map((space) => (
							<SpaceOverviewCell onClick={() => onSpacePick(space)} key={space.id} space={space} />
						))}
					</>
				)}
			</div>
			<LoadingIndicator isActive={showIndicator} icon={<Calendar />} text={t('find_spaces')} />
		</Screen>
	);
};

interface OverviewScreenProps {
	onBack(): void;
	onSpacePick(space: Space): void;
}
