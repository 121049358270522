import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDebouncedCallback } from 'use-debounce';
import { Dayjs } from 'dayjs';

import styles from './FiltersForm.module.css';

import { Availability, Space, SpaceCategory } from '../../types';
import { GroupSize } from '../../atoms/icons';
import { TimeSlotInput } from '../../atoms/timeslot-input';
import { Dropdown, Input } from '../../atoms/inputs';
import { config } from '../../utils/config';
import { useFilters } from '../../hooks/useFilters';
import { HttpService } from '../../utils/http';

const NUMBER_INPUT_DEBOUNCE = 1000; //ms
const httpService = new HttpService();

export const FiltersForm: React.FC<FiltersFormProps> = ({
	onChange,
	space,
	onTouch,
	onCalendarOpen,
	onCalendarClose,
}) => {
	const { t } = useTranslation();
	const { filters, setFilters } = useFilters();
	const debouncedOnChange = useDebouncedCallback((value) => {
		onChange?.(value);
	}, NUMBER_INPUT_DEBOUNCE);
	const eventTypes = config.eventType ?? [];
	const options = Object.values(eventTypes).map((type) => ({
		label: t('meeting_type.' + type),
		value: type + '',
	}));
	const [availability, setAvailability] = useState<Availability[]>([]);
	const [loading, setLoading] = useState<boolean>(false);

	const getAvailability = useCallback(
		async (date: Dayjs) => {
			let result;
			try {
				setLoading(true);
				if (space?.id) {
					result = await httpService.spaceAvailability(space?.id, date.format('YYYY-MM-DD'));
				} else {
					result = await httpService.venueAvailability(date.format('YYYY-MM-DD'));
				}
				setAvailability(result);
			} catch (e) {
				console.error(e);
			} finally {
				setLoading(false);
			}
		},
		[space?.id]
	);

	return (
		<div className={styles.container}>
			<div className={styles.row}>
				<div className={styles.leftColumn}>
					<Dropdown
						borderless
						value={filters.type}
						options={options}
						onFocus={() => onTouch?.()}
						onChange={(event) => {
							const value = event.target.value;
							setFilters((state) => ({
								...state,
								type: value as SpaceCategory,
							}));
							onChange?.({
								...filters,
								type: value as SpaceCategory,
							});
						}}
					/>
				</div>
				<div className={styles.rightColumn}>
					<Input
						icon={<GroupSize />}
						borderless
						type="number"
						max={space ? space.capacity.max : 999}
						min={space ? space.capacity.min : 1}
						inputMode="numeric"
						value={filters.persons}
						onFocus={() => onTouch?.()}
						onChange={(event) => {
							const { value } = event.target;

							setFilters((state) => ({
								...state,
								persons: value ? Math.max(Number(value), 1) : 0,
							}));
							debouncedOnChange({
								...filters,
								persons: value ? Math.max(Number(value), 1) : 0,
							});
						}}
					/>
				</div>
			</div>
			<div>
				<TimeSlotInput
					borderless
					loading={loading}
					value={{ startDate: filters.startDate, endDate: filters.endDate }}
					onFocus={() => onTouch?.()}
					onChange={({ startDate, endDate }) => {
						setFilters((state) => ({ ...state, startDate, endDate }));
						onChange?.({ ...filters, startDate, endDate });
					}}
					onCalendarOpen={() => {
						onCalendarOpen?.();
						getAvailability(filters.startDate);
					}}
					onCalendarClose={onCalendarClose}
					onMonthChange={(month) => {
						getAvailability(month);
					}}
					availability={availability}
				/>
			</div>
		</div>
	);
};

interface FiltersFormProps {
	onChange?(value: any): void;
	onTouch?(): void;
	onCalendarOpen?(): void;
	onCalendarClose?(): void;
	space?: Space;
}
