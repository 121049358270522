import React from 'react';

export const ChevronDown: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="8" height="6" viewBox="0 0 8 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M0.9375 0.71875L4 3.78125L7.0625 0.71875L8 1.65625L4 5.65625L0 1.65625L0.9375 0.71875Z"
				fill="#707D83"
			/>
		</svg>
	);
};

export default ChevronDown;
