import React from 'react';

export const Water: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="19" height="19" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M26.907 3.36c-0.177-0.197-0.432-0.32-0.716-0.32-0.002 0-0.003 0-0.005 0h-20.373c-0.002 0-0.004-0-0.006-0-0.53 0-0.96 0.43-0.96 0.96 0 0.038 0.002 0.075 0.006 0.111l-0-0.004 2.667 24c0.055 0.482 0.461 0.853 0.954 0.853 0.002 0 0.004 0 0.006-0h14.986c0.002 0 0.004 0 0.006 0 0.493 0 0.899-0.371 0.954-0.849l0-0.004 2.667-24c0.009-0.048 0.013-0.104 0.013-0.16 0-0.222-0.075-0.426-0.202-0.589l0.002 0.002zM22.667 27.040h-13.333l-1.813-16c0.469 0.119 0.869 0.368 1.171 0.705l0.002 0.002c0.709 0.77 1.722 1.25 2.847 1.25s2.138-0.48 2.844-1.247l0.002-0.003c0.367-0.458 0.926-0.748 1.553-0.748s1.186 0.29 1.55 0.744l0.003 0.004c0.709 0.77 1.722 1.25 2.847 1.25s2.138-0.48 2.844-1.247l0.002-0.003c0.305-0.338 0.705-0.587 1.157-0.703l0.016-0.004zM24.64 9.133c-1.052 0.060-1.987 0.518-2.665 1.225l-0.001 0.001c-0.367 0.458-0.926 0.748-1.553 0.748s-1.186-0.29-1.55-0.744l-0.003-0.004c-0.709-0.77-1.722-1.25-2.847-1.25s-2.138 0.48-2.844 1.247l-0.002 0.003c-0.367 0.458-0.926 0.748-1.553 0.748s-1.186-0.29-1.55-0.744l-0.003-0.004c-0.679-0.708-1.614-1.166-2.656-1.226l-0.011-0.001-0.507-4.173h18.213z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default Water;
