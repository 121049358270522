import React from 'react';
import styles from './Setup.module.css';
import { SpaceSetup } from '../../types';
import { Text, BoldText } from '../typography';
import { useTranslation } from 'react-i18next';

export const Setup: React.FC<SetupTypeProps> = ({ icon, setup }) => {
	const { t } = useTranslation();
	return (
		<div className={styles.wrapper}>
			<div className={styles.icon}>{icon}</div>
			<div className={styles.capacity}>
				<Text> {t(`setupOptions.${setup.label.toLowerCase()}`)}</Text>
				<BoldText> {setup.max}</BoldText>
			</div>
		</div>
	);
};

interface SetupTypeProps {
	icon: React.ReactNode;
	setup: SpaceSetup;
}
