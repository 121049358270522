import React from 'react';

export const Alcohol: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="19" height="19" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M24.2 0.973h-16c-0.53 0-0.96 0.43-0.96 0.96v0 9.547c0.003 4.572 3.435 8.342 7.864 8.876l0.043 0.004v8.973h-4.040c-0.571 0-1.033 0.463-1.033 1.033s0.463 1.033 1.033 1.033v0h10.227c0.571 0 1.033-0.463 1.033-1.033s-0.463-1.033-1.033-1.033v0h-4.080v-8.973c4.471-0.538 7.904-4.308 7.907-8.88v-9.547c0-0.53-0.43-0.96-0.96-0.96v0zM23.24 2.893v5.587c-0.514 0.248-0.954 0.561-1.334 0.934l0.001-0.001c-0.337 0.45-0.868 0.738-1.467 0.738s-1.13-0.288-1.463-0.733l-0.003-0.005c-0.68-0.775-1.673-1.262-2.78-1.262s-2.099 0.487-2.776 1.258l-0.004 0.004c-0.334 0.455-0.866 0.747-1.467 0.747s-1.133-0.292-1.463-0.742l-0.004-0.005c-0.379-0.373-0.819-0.685-1.304-0.92l-0.029-0.013v-5.587zM16.213 18.493c-3.87-0.008-7.006-3.143-7.013-7.013v-0.667c0.68 0.771 1.67 1.254 2.773 1.254s2.093-0.484 2.77-1.25l0.003-0.004c0.337-0.45 0.868-0.738 1.467-0.738s1.13 0.288 1.463 0.733l0.003 0.005c0.683 0.771 1.675 1.255 2.78 1.255s2.098-0.484 2.777-1.251l0.003-0.004v0.667c-0.008 3.87-3.143 7.006-7.013 7.013h-0.001z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default Alcohol;
