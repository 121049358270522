import React, { useState } from 'react';
import Select from 'react-select';

import styles from './PhoneInput.module.css';

const selectStyles = {
	control: (provided: any) => ({ ...provided, minWidth: 200, margin: 8 }),
	menu: () => ({ height: 220, borderTop: '1px solid #E4E7EA' }),
};

export const CountrySelect: React.FC<any> = ({ options = [], value, onChange, iconComponent }) => {
	const [menuOpen, setMenuOpen] = useState(false);

	return (
		<div>
			<button className={styles.button} onClick={() => setMenuOpen(true)}>
				{React.createElement(iconComponent, { country: value })}
			</button>
			{menuOpen && (
				<div className={styles.popup}>
					<Select
						autoFocus
						menuIsOpen
						controlShouldRenderValue={false}
						backspaceRemovesValue={false}
						placeholder="Search..."
						onMenuClose={() => {
							setMenuOpen(false);
						}}
						onChange={(option) => {
							onChange(option.value);
						}}
						components={{
							DropdownIndicator: null,
							IndicatorSeparator: null,
						}}
						value={options.find((option: any) => option.value === value)}
						options={[
							{
								label: 'Most used',
								options: options.filter(({ value }: any) => ['NL', 'DE', 'BE', 'GB'].includes(value)),
							},
							{
								label: 'Other',
								options: options.filter(({ value }: any) => Boolean(value)),
							},
						]}
						formatOptionLabel={({ label, value }) => (
							<span style={{ display: 'flex' }}>
								{React.createElement(iconComponent, { country: value })}
								<span style={{ marginLeft: 10 }}>{label}</span>
							</span>
						)}
						styles={selectStyles}
					/>
				</div>
			)}
		</div>
	);
};
