import React from 'react';

export const UncheckCircle: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 24 24" fill="none" {...props}>
			<circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />
		</svg>
	);
};

export default UncheckCircle;
