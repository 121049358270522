import React from 'react';
import classNames from 'classnames';

import styles from './PackageSkeleton.module.css';

export const PackageSkeleton: React.FC = () => {
	return (
		<div className={styles.container}>
			<div className={styles.bars}>
				<div className={classNames(styles.bar, styles.bar1)} />
				<div className={classNames(styles.bar, styles.bar2)} />
				<div className={classNames(styles.bar, styles.bar3)} />
				<div className={classNames(styles.bar, styles.bar4)} />
			</div>
			<div className={styles.checkbox} />
		</div>
	);
};
