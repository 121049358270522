import React from 'react';

export const Close: React.FC<React.SVGProps<SVGSVGElement>> = ({ color = '#707D83', ...props }) => {
	return (
		<svg width="12" height="12" focusable="false" aria-hidden="true" viewBox="0 0 12 12" {...props}>
			<path
				fill={color}
				d="M10.657 12L6 7.343 1.343 12 0 10.657 4.657 6 0 1.343 1.343 0 6 4.657 10.657 0 12 1.343 7.343 6 12 10.657z"
			/>
		</svg>
	);
};

export default Close;
