import { SpaceCategory, PriceUnit, CapacityCategory, Period } from './../types';

export default {
	apply: 'Toepassen',
	welcome: 'Welkom bij Venuesuite',
	persons: 'persoon',
	persons_plural: 'personen',
	eventType: 'Soort bijeenkomst',
	personsAmount: 'Aantal personen',
	date: 'Datum',
	startTime: 'Starttijd',
	endTime: 'Eindtijd',
	company: 'Bedrijf',
	time_slots: {
		morning: 'Ochtend',
		afternoon: 'Middag',
		full_day: 'Dag',
	},
	per_person: 'per persoon',
	tax: 'Tax',
	excl_tax: 'excl. BTW',
	incl_tax: 'incl. BTW',
	heading_subtitle: '{{count}} persoon · {{startDate, D MMM}} · {{startDate, HH:mm}}-{{endDate, HH:mm}}',
	heading_subtitle_plural: '{{count}} personen · {{startDate, D MMM}} · {{startDate, HH:mm}}-{{endDate, HH:mm}}',
	meeting_type: {
		[SpaceCategory.Meeting]: 'Vergadering',
		[SpaceCategory.Training]: 'Training',
		[SpaceCategory.Workshop]: 'Workshop',
		[SpaceCategory.Reception]: 'Receptie',
		[SpaceCategory.Dinner]: 'Diner',
		[SpaceCategory.Other]: 'Overig',
	},
	price_unit: {
		[PriceUnit.PerPerson]: '/ persoon',
		[PriceUnit.PerDayPart]: '/ dagdeel',
		[PriceUnit.PerHourPerPerson]: '/ uur (p.p.)',
		[PriceUnit.Total]: '/ totaal',
	},
	price_period: {
		[Period.DayPart]: ' / dagdeel',
		perDaypart: '/ dagdeel',
		[Period.Day]: '/ dag',
		perDay: '/ dag',
		[Period.Hour]: '/ uur',
		perHour: '/ uur',
		[Period.Package]: ' / persoon',
	},
	extraCategory: {
		equipment: 'Techniek',
		food: 'Eten',
		beverages: 'Dranken',
	},
	extrasTitle: "Extra's op aanvraag",
	extrasDescription: 'Na het reserveren zijn deze items en meer online aan te vragen',
	free: 'Inclusief',
	spaces: 'Selecteer ruimte',
	including: 'Inclusief - {{equipment}}',
	capacity: '{{min}} - {{max}} personen',
	comments_questions: 'Opmerkingen & vragen (optioneel)',
	email: 'E-mail',
	email_help: 'De bevestiging van je aanvraag wordt naar dit adres gemaild.',
	reservation_explanation: `1. 
We zullen je aanvraag zo snel mogelijk bevestigen als optionele reservering (meestal binnen een paar uur)

2. 
Je krijgt via de mail toegang tot jouw online aanvraag. Hier blijf je via de chat met ons in contact voor eventuele vragen of aanpassingen.

3.
Vervolgens kun je met een druk op de knop de optie definitief bevestigen of kosteloos annuleren.`,
	book: 'reservering aanvragen',
	bookNow: 'Online reserveren',
	priceAvailability: 'Toon beschikbare ruimtes',
	reserve: 'Toon prijsoverzicht {{price}}',
	space_included: 'Ruimte inclusief',
	onlySpace: "Ruimte + extra's op aanvraag",
	onlySpaceDescription: 'Catering en techniek kan gemakkelijk na het reserveren aangevraagd worden',
	packages: 'Pakketten',
	catering: 'Catering',
	equipment: 'Materiaal',
	packagesUnavailable: 'Helaas zijn er geen pakketten beschikbaar voor deze locatie',
	cateringUnavailable: 'Helaas is er geen catering beschikbaar voor deze locatie',
	equipmentUnavailable: 'Helaas is er geen materiaal beschikbaar voor deze locatie',
	find_spaces: 'Ruimtes ophalen',
	find_packages: 'Arrangementen ophalen',
	show_spaces: 'Bekijk locatie',
	aboutSpace: 'Over deze ruimte',
	reservationType: 'Selecteer arrangement',
	summary: 'Jouw {{spaceType}}',
	terms: 'Wat gebeurt er als je een reservering aanvraagt?',
	errors: {
		validation: {
			persons: 'Vul een aantal in',
			required: 'Dit veld is verplicht',
			email: 'Vul een geldig e-mailadres in',
			name: 'Vul je volledige naam in',
			company: 'Vul je bedrijfsnaam in',
			telephone: 'Vul een correct telefoonnummer in',
			time: 'De starttijd moet voor de eindtijd liggen',
			after24: 'De starttijd moet minimaal 24 uur na nu zijn',
		},
		network: {
			fetch_spaces:
				'Er ging iets fout bij het laden van de beschikbare ruimtes. Probeer het alsjeblieft opnieuw.',
			fetch_space: 'Er ging iets fout bij het laden van ruimte. Probeer het alsjeblieft opnieuw.',
			fetch_invoice: 'Er ging iets fout bij het laden van quote. Probeer het alsjeblieft opnieuw.',
			book: 'Er ging iets fout reserveren van de ruimte. Probeer het alsjeblieft opnieuw.',
			space: 'Er ging iets fout reserveren van de ruimte. Probeer het alsjeblieft opnieuw.',
		},
	},
	success: {
		title: 'Bedankt voor jouw aanvraag!',
		description:
			'Wij hebben een mail naar <1>{{email}}</1> gestuurd met een link waarmee jouw account kan worden geactiveerd om:',
		checkmark: [
			'De reservering aan te passen',
			'Met een account manager te chatten',
			'Op de hoogte te blijven van wijzigingen',
		],
		check_spam: 'E-mail niet ontvangen? Check voor de zekerheid even je spam folder',
	},
	done: 'Klaar',
	time_range: '{{startDate, HH:mm}} tot {{endDate, HH:mm}}',
	noSpaces: 'Sorry wij kunnen geen ruimtes vinden die voldoen aan jouw zoekopdracht.',
	noSpacesClick: 'Pas de criteria aan om een beschikbare ruimte te vinden.',
	subtotal: 'Subtotaal',
	vat: 'BTW {{percentage}}%',
	total: 'Totaal',
	accomodation_type: {
		double: 'Tweepersoonskamer',
		single: 'Eenpersoonskamer',
	},
	many_accomodations: 'Meer dan 20',
	accomodation_subtitle: 'Hotelkamers',
	accomodation_text: 'Voor een overnachting zijn de volgende kamers beschikbaar',
	equipmentOptions: {
		PROJECTOR: 'Projector',
		TV_SCREEN: 'TV Scherm',
		PROJECTION_AND_SCREEN: 'Projector & Scherm',
		MONITOR: 'Monitor',
		BEAMER: 'Beamer',
		SPEAKERS: 'Speakers',
		SOUND_SYSTEM: 'Geluidssysteem',
		MICROPHONE: 'Microfoon',
		LASER_POINTER: 'Laserpen',
		AUDIO_ENGINEER: 'Geluidstechnicus',
		LAPTOP: 'Laptop',
		EXTRA_CHAIRS: 'Extra Stoelen',
		EXTRA_TABLES: 'Extra Tafels',
		LIGHTING: 'Belichting',
		SPEAKERS_DESK: 'Katheder',
		WARDROBE: 'Garderobe',
		VIDEO_CAMERA: 'Video Camera',
		CONFERENCE_PHONE: 'Conference Telefoon',
		PENS_AND_PAPER: 'Pen & Papier',
		WHITEBOARD: 'Whiteboard',
		FLIPCHART: 'Flipover',
		HEADSET: 'Koptelefoon',
		STAGE: 'Podium',
		DAYLIGHT: 'Daglicht',
	},
	setupOptions: {
		[CapacityCategory.Cabaret]: 'Cabaret',
		[CapacityCategory.Carre]: 'Carré',
		[CapacityCategory.Circle]: 'Cirkel',
		[CapacityCategory.Exam]: 'Examen',
		[CapacityCategory.School]: 'School',
		[CapacityCategory.Theater]: 'Theater',
		[CapacityCategory['U-Shape']]: 'U-vorm',
		u_shape: 'U-vorm',
		[CapacityCategory.Standing]: 'Staand',
		[CapacityCategory.Party]: 'Feest',
		[CapacityCategory.Dinner]: 'Diner',
		[CapacityCategory.Boardroom]: 'Directiekamer',
	},
	features: {
		lunch: 'Lunch',
		softdrinks: 'Softdrinks',
		alcoholic: 'Alcohol',
		water: 'Water',
		coffee_and_tea: 'Coffee and tea',
		dinner: 'Dinner',
		snacks: 'Sancks',
	},
	setupTitle: 'Capaciteit',
	full_name: 'Volledige naam',
	telephone: 'Telefoonnummer',
	contact_details: 'Contactgegevens',
	contact_details_help: 'Met deze gegevens kunnen wij contact met jou opnemen.',
	loading: 'Laden',
	partial_availability: 'Beperkt beschikbaar',
};
