import React, { HTMLAttributes } from 'react';
import classNames from 'classnames';

import styles from './Typography.module.css';

export const Text: React.FC<HTMLAttributes<HTMLParagraphElement> & { truncate?: boolean }> = ({
	className = '',
	children,
	truncate = false,
	...otherProps
}) => (
	<span {...otherProps} className={classNames(className, styles.text, { [styles.truncate]: truncate })}>
		{children}
	</span>
);
