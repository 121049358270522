import React, { HTMLAttributes } from 'react';
import styles from './Field.module.css';
import { Label, ErrorText, Help } from '../typography';

export const Field: React.FC<FieldProps> = ({
	label,
	help,
	error,
	children,
	reserveSpaceForError = true,
	className = '',
	...otherProps
}) => (
	<div {...otherProps} className={`${styles.field} ${className}`}>
		{!!label && (
			<label className={styles.label}>
				<Label>{label}</Label>
				{help && <Help>{help}</Help>}
				<div className={styles.innerField}>{children}</div>
			</label>
		)}
		{!label && children}
		{!!error && (
			<div className={reserveSpaceForError ? styles.error : styles.absoluteError}>
				<ErrorText>{error}</ErrorText>
			</div>
		)}
	</div>
);

interface FieldProps extends HTMLAttributes<HTMLDivElement> {
	label?: string;
	help?: string;
	reserveSpaceForError?: boolean;
	error?: string | null;
}
