import React from 'react';

export const Breakfast: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="19" height="19" viewBox="0 0 34 34" fill="none" {...props}>
			<path
				fill="currentColor"
				d="M14,7.46a.72.72,0,0,0,.43.92A.71.71,0,0,0,15.31,8a5,5,0,0,0-.17-3.81A2.22,2.22,0,0,1,15,2.33a.72.72,0,0,0-1.35-.49,3.57,3.57,0,0,0,.16,2.81A3.57,3.57,0,0,1,14,7.46Z"
			/>
			<path
				fill="currentColor"
				d="M21.33,10.57c0-.11,0-.22.05-.33a.74.74,0,0,0-.19-.55.72.72,0,0,0-.53-.23h-8A5,5,0,0,0,12.4,6a2.22,2.22,0,0,1-.15-1.81.72.72,0,1,0-1.35-.5,3.6,3.6,0,0,0,.15,2.82,3.56,3.56,0,0,1,.16,2.81s0,.07,0,.11H5.49A.73.73,0,0,0,5,9.69a.74.74,0,0,0-.18.55,8.35,8.35,0,0,0,2.42,5.13h0l-3.34.29a.72.72,0,0,0-.66.67l0,.82-1.64.37a.73.73,0,0,0-.54.53l-.17.69a.75.75,0,0,0,.11.6.72.72,0,0,0,.52.3L3,19.78l0,.46a.71.71,0,0,0,.59.75l2.58.46a.72.72,0,0,0,.52.61l3.49.94.19,0a.72.72,0,0,0,.57-.29l2.46.89a.67.67,0,0,0,.24,0,.72.72,0,0,0,.65-.39l.2-.42,1.42.67a.81.81,0,0,0,.31.06.8.8,0,0,0,.3-.06.72.72,0,0,0,.39-.46l.2-.69a.72.72,0,0,0-.21-.72L15.63,20.5l.37-.73a.72.72,0,0,0-.23-.91l-1.63-1.13a8.31,8.31,0,0,0,5.67-3.35,2.09,2.09,0,0,0,1.33.49,2.15,2.15,0,0,0,.19-4.3ZM4.59,17.05l2.34-.2L6.35,20l-1.92-.33Zm4-1.43,3.05.82-1.79,5-2.21-.59Zm4.64,6.43-1.83-.67,1.09-3,1.92,1.35Zm0-5.71.06-.15a.72.72,0,0,0-.49-.94L8.23,14a.65.65,0,0,0-.31,0,6.9,6.9,0,0,1-1.6-3.12h13.5A6.85,6.85,0,0,1,13.22,16.34Z"
			/>
		</svg>
	);
};

export default Breakfast;
