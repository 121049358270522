export enum AspectRatioOption {
	SQUARE = '1:1',
	WIDE = '4:3',
}

export const getImageSource = (
	uri: string,
	aspectRatio: AspectRatioOption = AspectRatioOption.SQUARE,
	width: number = Math.round(window.innerWidth)
) => {
	const uploadUrlPart = 'res.cloudinary.com';

	if (!uploadUrlPart || !uri.includes(uploadUrlPart)) {
		return uri;
	}

	const widthOption = `w_${width}`;
	const qualityOption = 'q_60';
	const contentOption = 'c_fill';
	const aspectOption = `ar_${aspectRatio}`;

	const config = `${widthOption},${qualityOption},${contentOption},${aspectOption}`;

	if (uri.includes(qualityOption)) {
		// it has already been modified
		return uri;
	}
	return [...uri.split('/').slice(0, 6), config, ...uri.split('/').slice(6)].join('/');
};
