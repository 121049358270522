export default {
	book: 'Request reservation',
	terms: 'What happens when i click “request reservation”?',
	reservation_explanation: `1. 
You will receive a confirmation of your request including a quote.

2. 
We will respond as soon as possible (usually within a few hours).

3.
You can confirm the optional reservation or cancel free of charge.`,
	success: {
		title: 'Thank you for your request!',
		description: 'We have sent a confirmation of your request including quote to <1>{{email}}</1>.',
		next_step: 'We will respond as soon as possible (usually within a few hours).',
	},
};
