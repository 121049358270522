import React from 'react';

export const Cabaret: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="19" height="14" fill="none" {...props}>
			<path
				fill="currentColor"
				d="M3.655 5.138H1.253a.562.562 0 00-.41.176.562.562 0 00-.176.41v2.402c0 .17.058.313.175.43a.562.562 0 00.41.176h2.403c.17 0 .312-.059.43-.176a.584.584 0 00.175-.43V5.724a.562.562 0 00-.175-.41.584.584 0 00-.43-.176zm-.606 2.383H1.86V6.329h1.19v1.192zm.606 2.402H1.253a.562.562 0 00-.41.176.584.584 0 00-.176.43v2.382c0 .17.058.313.175.43a.562.562 0 00.41.176h2.403c.17 0 .312-.059.43-.176a.584.584 0 00.175-.43V10.53a.584.584 0 00-.175-.43.585.585 0 00-.43-.176zm-.606 2.402H1.86v-1.21h1.19v1.21zm5.41-2.402H6.058a.585.585 0 00-.43.176.585.585 0 00-.175.43v2.382c0 .17.058.313.176.43.117.117.26.176.43.176H8.46a.562.562 0 00.41-.176.584.584 0 00.176-.43V10.53a.584.584 0 00-.176-.43.562.562 0 00-.41-.176zm-.605 2.402H6.663v-1.21h1.191v1.21zm5.39-2.402h-2.402a.562.562 0 00-.41.176.584.584 0 00-.175.43v2.382c0 .17.058.313.175.43a.562.562 0 00.41.176h2.403c.17 0 .312-.059.43-.176a.584.584 0 00.175-.43V10.53a.584.584 0 00-.175-.43.585.585 0 00-.43-.176zm-.605 2.402h-1.191v-1.21h1.191v1.21zm5.41-7.187h-2.402a.585.585 0 00-.43.176.562.562 0 00-.175.41v2.402c0 .17.058.313.175.43.118.117.26.176.43.176h2.402a.562.562 0 00.41-.176.584.584 0 00.176-.43V5.724a.562.562 0 00-.175-.41.562.562 0 00-.41-.176zm-.605 2.383h-1.191V6.329h1.191v1.192zm.605 2.402h-2.402a.585.585 0 00-.43.176.585.585 0 00-.175.43v2.382c0 .17.058.313.175.43.118.117.26.176.43.176h2.402a.562.562 0 00.41-.176.584.584 0 00.176-.43V10.53a.585.585 0 00-.175-.43.562.562 0 00-.41-.176zm-.605 2.402h-1.191v-1.21h1.191v1.21zM6.057.333a.585.585 0 00-.43.176.584.584 0 00-.175.43v7.187c0 .17.058.313.176.43.117.117.26.176.43.176h7.187a.588.588 0 00.43-.176.584.584 0 00.175-.43V.94a.584.584 0 00-.176-.43.584.584 0 00-.43-.176H6.058z"
			></path>
		</svg>
	);
};

export default Cabaret;
