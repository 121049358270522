import React from 'react';

export const Standing: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="19"
			height="19"
			viewBox="0 0 32 32"
			{...props}
			fill="currentColor"
		>
			<path d="M14.08 9.333h-3.827a.961.961 0 00-.92.918V14.08c0 .53.43.96.96.96h3.827c.53 0 .96-.43.96-.96v-3.827a.961.961 0 00-.959-.921l-.043.001zm-.96 3.787h-1.907v-1.907h1.907zm8.627 3.84H17.92a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96h3.827c.53 0 .96-.43.96-.96V17.92a.96.96 0 00-.96-.96zm-.96 3.827H18.88V18.88h1.907zm8.64-19.16H25.6a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96h3.827c.53 0 .96-.43.96-.96V2.587a.96.96 0 00-.96-.96zm-.96 3.826H26.56V3.546h1.907zm.96 3.88H25.6a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96h3.827c.53 0 .96-.43.96-.96v-3.867a.96.96 0 00-.959-.92zm-.96 3.827H26.56v-1.947h1.907zm.96 11.467H25.6a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96h3.827c.53 0 .96-.43.96-.96v-3.827a.96.96 0 00-.96-.96zm-.96 3.826H26.56v-1.907h1.907zm-6.72-26.826H17.92a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96h3.827a.96.96 0 00.92-.959V2.586a.96.96 0 00-.918-.959zm-.96 3.826H18.88V3.546h1.907zM6.4 1.627H2.573a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96H6.4c.53 0 .96-.43.96-.96V2.587a.96.96 0 00-.96-.96zm-.96 3.826H3.533V3.546H5.44zm.96 3.88H2.573a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96H6.4c.53 0 .96-.43.96-.96v-3.867a.96.96 0 00-.959-.92zm-.96 3.787H3.533v-1.907H5.44zm.96 3.84H2.573a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96H6.4c.53 0 .96-.43.96-.96V17.92a.96.96 0 00-.96-.96zm-.96 3.827H3.533V18.88H5.44zm.96 3.84H2.573a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96H6.4c.53 0 .96-.43.96-.96v-3.827a.96.96 0 00-.96-.96zm-.96 3.826H3.533v-1.907H5.44zm8.64-26.826h-3.827a.96.96 0 00-.92.959v3.828c0 .53.43.96.96.96h3.827c.53 0 .96-.43.96-.96V2.586a.96.96 0 00-.96-.96l-.042.001zm-.96 3.826h-1.907V3.546h1.907z"></path>
		</svg>
	);
};

export default Standing;
