import React from 'react';

export const Coffee: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="19" height="19" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M16.36 9.973c-0.041 0.103-0.064 0.222-0.064 0.347 0 0.533 0.432 0.964 0.964 0.964 0.408 0 0.757-0.253 0.898-0.611l0.002-0.007c0.301-0.792 0.476-1.707 0.476-2.663 0-1.198-0.274-2.332-0.762-3.343l0.020 0.046c-0.322-0.564-0.512-1.239-0.512-1.958 0-0.509 0.095-0.995 0.268-1.443l-0.009 0.027c0.014-0.061 0.022-0.132 0.022-0.204 0-0.53-0.43-0.96-0.96-0.96-0.376 0-0.702 0.216-0.859 0.532l-0.002 0.006c-0.229 0.601-0.362 1.295-0.362 2.021 0 0.95 0.227 1.846 0.63 2.639l-0.015-0.033c0.394 0.764 0.626 1.669 0.626 2.627 0 0.723-0.132 1.415-0.372 2.054l0.013-0.040zM28 29.933h-27c-0.53 0-0.96 0.43-0.96 0.96s0.43 0.96 0.96 0.96v0h27c0.53 0 0.96-0.43 0.96-0.96s-0.43-0.96-0.96-0.96v0zM28.133 15.28c0.108-0.452 0.202-1.011 0.261-1.579l0.005-0.061c0.003-0.028 0.005-0.060 0.005-0.093 0-0.53-0.43-0.96-0.96-0.96-0.002 0-0.003 0-0.005 0h-12.16c0.083-0.434 0.131-0.933 0.131-1.443 0-1.223-0.274-2.382-0.765-3.419l0.021 0.049c-0.311-0.556-0.494-1.22-0.494-1.926 0-0.491 0.088-0.961 0.25-1.395l-0.009 0.028c0.023-0.078 0.036-0.168 0.036-0.261 0-0.53-0.43-0.96-0.96-0.96-0.388 0-0.722 0.23-0.874 0.562l-0.002 0.006c-0.229 0.601-0.362 1.295-0.362 2.021 0 0.95 0.227 1.846 0.63 2.639l-0.015-0.033c0.405 0.784 0.643 1.711 0.643 2.693 0 0.511-0.064 1.008-0.186 1.481l0.009-0.041h-11.373c-0.001 0-0.003 0-0.005 0-0.53 0-0.96 0.43-0.96 0.96 0 0.033 0.002 0.065 0.005 0.097l-0-0.004c0.686 7.026 6.563 12.472 13.712 12.472 4.62 0 8.708-2.274 11.207-5.764l0.028-0.042c0.493 0.444 1.148 0.717 1.866 0.72h0.001c0.050 0.003 0.108 0.005 0.167 0.005 1.586 0 2.872-1.286 2.872-2.872 0-1.527-1.192-2.776-2.697-2.866l-0.008-0zM14.667 24.173c-0.017 0-0.037 0-0.057 0-5.758 0-10.55-4.133-11.572-9.594l-0.011-0.073h23.32c-1.033 5.534-5.826 9.667-11.583 9.667-0.034 0-0.068-0-0.102-0l0.005 0z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default Coffee;
