export const colors = {
	white: '#ffffff',
	error: '#dc3545',
	success: '#1dc88b',
	outline: '#7952b325',
	primary: 'f0465a',
	secondary: '#1dc88b',
	secondaryTransparent: '#1dc88b10',
	grey100: '#E4E7EA',
	grey200: '#C4CCD4',
	grey600: '#616E7B',
	grey800: '#323F4A',
};
