import React, { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Screen } from '../../atoms/screen';
import { Button } from '../../atoms/button';
import { Title } from '../../atoms/typography';

import { FiltersForm } from '../../molecules/filters-overview/FiltersForm';

import styles from './FiltersScreen.module.css';
import { Close } from '../../atoms/icons';
import { useMessage } from '../../hooks/useMessage';
import { PromoLink } from '../../atoms/promo-link';

export const FiltersScreen: React.FC<FiltersScreenProps> = ({ onBack, onNext }) => {
	const { t } = useTranslation();
	const [sendMessage] = useMessage();

	useLayoutEffect(() => {
		sendMessage({ type: 'screen', value: 'small' });
	}, []);

	return (
		<Screen
			headerComponent={
				<div className={styles.header}>
					<div role="button" className={styles.button} onClick={onBack}>
						<Close width={8} height={8} />
					</div>
					<Title>{t('bookNow')}</Title>
				</div>
			}
		>
			<div className={styles.container}>
				<FiltersForm
					onCalendarOpen={() => {
						setTimeout(() => {
							sendMessage({ type: 'screen', value: 'large' });
						}, 200);
					}}
					onCalendarClose={() => sendMessage({ type: 'screen', value: 'small' })}
				/>
				<Button title={t('show_spaces')} onClick={onNext} />
				<PromoLink link="https://www.venuebookingwidget.com/?utm_source=Widget&utm_medium=bijeenkomst-selectie" />
			</div>
		</Screen>
	);
};

interface FiltersScreenProps {
	onBack(): void;
	onNext(): void;
}
