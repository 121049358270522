import React from 'react';
import MaskedInput, { Country, Value } from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';

import 'react-phone-number-input/style.css';

import { config } from '../../utils/config';

import { Input } from '../input';

import { CountrySelect } from './CountrySelect';

const getCountry = (locale: string): string | undefined => locale.split('-')[1];

export const PhoneInput: React.FC<PhoneInputProps> = ({ onChange, value, country = 'auto', onBlur, ...props }) => {
	if (country === 'auto') {
		country = (
			getCountry(navigator.language) ||
			getCountry(config.language) ||
			(config.language === 'EN' ? 'NL' : config.language)
		).toUpperCase() as Country;
	}

	return (
		<MaskedInput
			value={value}
			onChange={(value) => {
				onChange?.(value ?? ('' as any));
			}}
			onBlur={onBlur}
			flags={flags}
			defaultCountry={country}
			inputComponent={Input}
			countrySelectComponent={CountrySelect}
			limitMaxLength
			numberInputProps={{ containerStyles: { borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }, ...props }}
		/>
	);
};

interface PhoneInputProps {
	country?: Country | 'auto';
	value: Value;
	onChange?: (value: string) => void;
	onBlur?: (e: React.FocusEvent<any>) => void;
	postfix?: any;
	placeholder?: string;
}
