import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Accomodation } from '../../types';
import { formatCurrency } from '../../utils/currency';
import { config } from '../../utils/config';

import { useDeviceDetect } from '../../hooks/useDeviceDetect';

import { Text, BoldText, Subtitle } from '../../atoms/typography';
import { NumberSelect } from '../../atoms/number-select';
import Tooltip from '../../atoms/tooltip';

import styles from './AccomodationCell.module.css';

export const AccomodationCell: React.FC<AccomodationCellProps> = ({
	accomodation,
	selected,
	quantity,
	defaultQuantity,
	onQuantityChange,
}) => {
	const { t } = useTranslation();

	const [isTooltipOpened, setTooltipOpened] = useState<boolean>(false);

	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	const device = useDeviceDetect();

	/**
	 * Hide currently visible tooltip when clicking anywhere
	 * in order to prevent two tooltips showing at the same time
	 */
	useEffect(() => {
		if (showTooltip) {
			const handleBodyClick = () => {
				if (showTooltip) {
					setShowTooltip(false);
				}
			};
			document.body.addEventListener('click', handleBodyClick);
			return () => document.body.removeEventListener('click', handleBodyClick);
		}
	}, [showTooltip]);

	return (
		<div
			className={`${styles.cell} ${selected ? styles.selected : ''}`}
			onClick={() => onQuantityChange(quantity === 0 ? defaultQuantity : 0)}
		>
			<div className={styles.flex}>
				<div className={styles.titleContainer}>
					<div className={styles.tooltipContainer}>
						{(!device.isMobile || isTooltipOpened) && (
							<Tooltip
								visible={showTooltip}
								title={t(`accomodation_type.${accomodation.component}`)}
								description={accomodation.description}
							/>
						)}
					</div>
					{!device.isMobile && (
						<Subtitle
							className={styles.tooltipText}
							onMouseOver={(event) => {
								event.stopPropagation();
								setShowTooltip((value) => !value);
							}}
							onMouseLeave={(event) => {
								event.stopPropagation();
								setShowTooltip(() => false);
							}}
						>
							{accomodation.title}
						</Subtitle>
					)}

					{device.isMobile && (
						<Subtitle
							className={styles.tooltipText}
							onClick={(event) => {
								setTooltipOpened(!isTooltipOpened);

								event.stopPropagation();
								setShowTooltip((value) => !value);
							}}
						>
							{accomodation.title}
						</Subtitle>
					)}
				</div>
				<NumberSelect
					max={20}
					selected={selected}
					value={String(quantity)}
					isSingle={accomodation.component === 'single'}
					onClick={(e) => {
						e.stopPropagation();
					}}
					onChange={(newValue) => onQuantityChange(Number(newValue))}
				/>
			</div>

			<BoldText>
				{formatCurrency(config.show_tax ? accomodation.pricing.included : accomodation.pricing.excluded)}
			</BoldText>
			<Text> {t(`price_unit.${accomodation.pricing.unit}`)}</Text>
		</div>
	);
};

interface AccomodationCellProps {
	accomodation: Accomodation;
	quantity: number;
	defaultQuantity: number;
	selected?: boolean;
	onQuantityChange: (quantity: number) => void;
}
