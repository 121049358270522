import React from 'react';

export const SoftDrink: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="19" height="19" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M27.893 0.707c-0.139-0.371-0.49-0.63-0.902-0.63-0.117 0-0.228 0.021-0.332 0.059l0.007-0.002-7.533 2.667c-0.34 0.126-0.586 0.43-0.626 0.796l-0 0.004-0.467 4h-13.56c-0.528 0.003-0.954 0.432-0.954 0.96 0 0.042 0.003 0.084 0.008 0.125l-0.001-0.005 2.667 21.667c0.061 0.474 0.461 0.837 0.946 0.84h14.987c0.485-0.003 0.885-0.366 0.946-0.835l0-0.005 2.667-21.667c0.005-0.036 0.008-0.078 0.008-0.12 0-0.528-0.427-0.957-0.954-0.96h-4.8l0.373-3.227 6.973-2.507c0.348-0.149 0.587-0.489 0.587-0.885 0-0.098-0.015-0.193-0.042-0.282l0.002 0.007zM6.227 14.787l-0.653-5.2h12.253l-0.6 5.2zM21.333 29.333h-13.333l-1.533-12.627h10.547l-1.107 9.68c-0.004 0.034-0.007 0.073-0.007 0.113 0 0.49 0.368 0.895 0.842 0.953l0.005 0h0.107c0.489-0.004 0.891-0.374 0.946-0.849l0-0.004 1.133-9.893h3.92zM23.76 9.587l-0.653 5.2h-4l0.6-5.2zM13.040 26.12c0 0.53-0.43 0.96-0.96 0.96s-0.96-0.43-0.96-0.96c0-0.53 0.43-0.96 0.96-0.96s0.96 0.43 0.96 0.96zM11.44 22.6c0 0.53-0.43 0.96-0.96 0.96s-0.96-0.43-0.96-0.96c0-0.53 0.43-0.96 0.96-0.96s0.96 0.43 0.96 0.96zM14.32 20.36c0 0.53-0.43 0.96-0.96 0.96s-0.96-0.43-0.96-0.96c0-0.53 0.43-0.96 0.96-0.96s0.96 0.43 0.96 0.96z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default SoftDrink;
