import React, { HTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import styles from './PriceMatrix.module.css';
import { formatCurrency } from '../../utils/currency';
import { Text, BoldText } from '../../atoms/typography';
import { Invoice } from '../../types';

export const PriceMatrix: React.FC<PriceMatrixProps> = ({ invoice }) => {
	const { t } = useTranslation();
	return (
		<div className={styles.container}>
			{invoice.details.map(({ description, quantity, price }, index) => (
				<div key={index} className={styles.row}>
					<Text truncate>
						{quantity > 1 && `${quantity}x `}
						{description}
					</Text>
					<Text>
						{invoice.details[index].category === 'space' && price < 1
							? t('free')
							: formatCurrency(price * 100)}
					</Text>
				</div>
			))}
			<div className={styles.divider} />
			<div className={styles.row}>
				<Text>{t('subtotal')}</Text>
				<BoldText className={styles.total}>{formatCurrency(invoice.summary.price * 100)}</BoldText>
			</div>
			<div className={styles.row}>
				<BoldText>{`${t('total')} ${t('incl_tax')}`}</BoldText>
				<BoldText className={styles.total}>{formatCurrency(invoice.summary.total_price * 100)}</BoldText>
			</div>
			<div className={styles.row}>
				<Text>{t('per_person')}</Text>
				<BoldText>{formatCurrency(invoice.summary.total_price_per_person * 100)}</BoldText>
			</div>
		</div>
	);
};

interface PriceMatrixProps extends HTMLAttributes<HTMLDivElement> {
	invoice: Invoice;
}
