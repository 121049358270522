import React from 'react';
import { config } from '../../utils/config';

export const Check: React.FC<React.SVGProps<SVGSVGElement>> = ({ color = config.color || '#707D83', ...props }) => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path d="M6 10.8L3.2 8L2.26666 8.93333L6 12.6667L14 4.66667L13.0667 3.73333L6 10.8Z" fill={color} />
		</svg>
	);
};

export default Check;
