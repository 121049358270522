import React from 'react';

import { Space, SpaceSetup } from '../../types';
import styles from './SetupOverview.module.css';

import { Setup } from '../../atoms/setup';
import {
	Cabaret,
	Carre,
	Circle,
	Exam,
	Turn,
	School,
	Theater,
	Boardroom,
	Dinner,
	Party,
	Standing,
} from '../../atoms/icons';

export const SetupOverview: React.FC<SetupOverviewProps> = ({ space }) => {
	const setups = space.setups;

	const icons = {
		cabaret: <Cabaret />,
		carre: <Carre />,
		circle: <Circle />,
		exam: <Exam />,
		'u-shape': <Turn />,
		school: <School />,
		theater: <Theater />,
		boardroom: <Boardroom />,
		dinner: <Dinner />,
		party: <Party />,
		standing: <Standing />,
	} as any;

	return (
		<div className={styles.container}>
			{setups.map((setup: SpaceSetup) => (
				<div key={setup.label} className={styles.setup}>
					<Setup icon={icons[setup.label.toLowerCase()]} setup={setup} />
				</div>
			))}
		</div>
	);
};

interface SetupOverviewProps {
	space: Space;
}
