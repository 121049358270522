import React, { useEffect } from 'react';
import { render } from 'react-dom';
import styles from './Error.module.css';
import { Close } from '../../atoms/icons';

const ERROR_HIDE_DELAY = 4000;

export const Error: React.FC<ErrorProps> = ({ text, container }) => {
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (document.body.contains(container)) {
				document.body.removeChild(container);
			}
		}, ERROR_HIDE_DELAY);

		return () => clearTimeout(timeout);
	}, [container]);

	return (
		<div className={styles.errorClass}>
			<div>{text}</div>
			<div
				className={styles.closeButton}
				onClick={(e) => {
					e.stopPropagation();
					document.body.removeChild(container);
				}}
			>
				<Close width={10} height={10} />
			</div>
		</div>
	);
};

export const showError = ({ text }: showErrorOptions) => {
	const container = document.createElement('div');
	document.body.append(container);

	render(<Error text={text} container={container} />, container);
};

interface showErrorOptions {
	text: string;
}

interface ErrorProps {
	text: string;
	container: Node;
}
