import React from 'react';

export const Dinner: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 32 32" {...props}>
			<path
				fill="currentColor"
				d="M29.433 13c.25 0 .469.094.656.281s.281.417.281.688v3.813c0 .271-.094.5-.281.688s-.406.281-.656.281h-3.844c-.271 0-.5-.094-.688-.281s-.281-.417-.281-.688v-3.813c0-.271.094-.5.281-.688s.417-.281.688-.281zM26.5 15v2h2v-2zM6.433 13c.25 0 .469.094.656.281s.281.417.281.688v3.813c0 .271-.094.5-.281.688s-.406.281-.656.281H2.589c-.271 0-.5-.094-.688-.281s-.281-.417-.281-.688v-3.813c0-.271.094-.5.281-.688S2.318 13 2.589 13zM3.5 15v2h2v-2zm6.75-5.719h11.5c.271 0 .5.094.688.281s.281.417.281.688v11.5c0 .271-.094.5-.281.688s-.417.281-.688.281h-11.5c-.271 0-.5-.094-.688-.281s-.281-.417-.281-.688v-11.5c0-.271.094-.5.281-.688s.417-.281.688-.281zm7.563 15.339c.25 0 .469.094.656.281s.281.417.281.688v3.813c0 .271-.094.5-.281.688s-.406.281-.656.281h-3.844c-.271 0-.5-.094-.688-.281S13 29.673 13 29.402v-3.813c0-.271.094-.5.281-.688s.417-.281.688-.281zm-2.813 2v2h2v-2zM17.813 2c.25 0 .469.094.656.281s.281.417.281.688v3.813c0 .271-.094.5-.281.688s-.406.281-.656.281h-3.844c-.271 0-.5-.094-.688-.281S13 7.053 13 6.782V2.969c0-.271.094-.5.281-.688S13.698 2 13.969 2zM15 4v2h2V4z"
			></path>
		</svg>
	);
};

export default Dinner;
