import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import styles from './LoadingIndicator.module.css';
import { Spinner } from '../icons';
import { config } from '../../utils/config';

const Ellipses = () => {
	const [ellipses, setEllipses] = useState('.');

	useEffect(() => {
		const interval = setInterval(() => {
			setEllipses((state) => (state.length === 3 ? '.' : `${state}.`));
		}, 300);

		return () => {
			clearInterval(interval);
		};
	}, []);

	return <span>{ellipses}</span>;
};

export const LoadingIndicator: React.FC<LoadingIndicatorProps> = ({
	icon,
	text,
	isActive = false,
	withFooter = false,
}) => {
	return (
		<div
			className={classNames(styles.loader, {
				[styles.active]: isActive,
				[styles.withFooter]: withFooter,
			})}
		>
			<div className={styles.spinnerWrapper}>
				<div className={styles.spinner}>
					<Spinner color={config.color} />
				</div>
				<div className={styles.icon}>{icon && React.cloneElement(icon, { color: config.color })}</div>
			</div>
			<div className={styles.text}>
				{text}
				<Ellipses />
			</div>
		</div>
	);
};

interface LoadingIndicatorProps {
	icon?: React.ReactElement;
	text?: React.ReactNode;
	isActive?: boolean;
	withFooter?: boolean;
}
