import { useEffect } from 'react';

export const useMessageListener = (
	messageType: string,
	callback: (message: { type: string; value: string | Record<string, any> }) => void
) => {
	useEffect(() => {
		const handleMessage = ({ data, isTrusted }: any) => {
			if (data.type === messageType && isTrusted) {
				callback(data);
			}
		};

		window.addEventListener('message', handleMessage);

		return () => {
			window.removeEventListener('message', handleMessage);
		};
	}, [callback, messageType]);
};
