import React from 'react';

import styles from './PromoLink.module.css';

const PromoLink: React.FC<{ link: string }> = ({ link }) => (
	<div className={styles.promoLink}>
		<div className={styles.divider} />
		<div className={styles.container}>
			Powered by{' '}
			<a href={link} target="_blank" rel="noopener noreferrer">
				Venuesuite
			</a>
		</div>
	</div>
);

export default PromoLink;
