import React from 'react';

import { Catering, Equipment } from '../../types';
import { formatCurrency } from '../../utils/currency';
import { config } from '../../utils/config';

import { Text, SmallBoldText, Subtitle } from '../../atoms/typography';
import { ChevronDown } from '../../atoms/icons';

import styles from './Accordion.module.css';

export const Accordion: React.FC<AccordionProps> = ({ title, items, selected, onClick }) => {
	return (
		<div className={`${styles.container} ${selected ? styles.selected : ''}`}>
			<div role="button" onClick={onClick} className={styles.titleContainer}>
				<Subtitle className={styles.title}>{title}</Subtitle>
				<div className={styles.chevronContainer}>
					<ChevronDown />
				</div>
			</div>
			<div className={styles.extendable}>
				{items.map((item, index) => (
					<div className={styles.item} key={index}>
						<Text>{item.title}</Text>
						<SmallBoldText>
							{formatCurrency(
								item.pricing ? (config.show_tax ? item.pricing.included : item.pricing.excluded) : 0
							)}
						</SmallBoldText>
					</div>
				))}
			</div>
		</div>
	);
};

interface AccordionProps {
	title: string;
	items: (Catering | Equipment)[];
	selected?: boolean;
	onClick?: () => void;
}
