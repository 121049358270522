export default {
	book: 'Reservierung Anfragen',
	terms: 'Was passiert wenn eine Option Angefragt wird?',
	reservation_explanation: `1. 
Sie erhalten direkt eine Bestaetigung Ihrer Anfrage - inklusive eines entsprechenden Angebotes.

2. 
Wir werden uns so schnell wie möglich auf Ihre Anfrage reagieren (meistens innerhalb einer Stunde).

3.
Anschliessend koennen Sie die Option definitief Bestaetigen oder kostenlos stornieren.`,
	success: {
		title: 'Vielen Dank für Ihre Anfrage!',
		description: 'Wir haben Ihnen eine Bestätigungs Email an <1>{{email}}</1> gesendet.',
		next_step:
			'Wir werden uns so schnell wie möglich auf Ihre Anfrage reagieren (meistens innerhalb einer Stunde).',
	},
};
