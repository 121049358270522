import React from 'react';

export const Exam: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" fill="none" {...props}>
			<path
				fill="currentColor"
				d="M8.46 4.26H1.253a.562.562 0 01-.41-.175.584.584 0 01-.176-.43V1.272c0-.17.058-.312.175-.43a.562.562 0 01.41-.175H8.46c.156 0 .293.058.41.175.117.118.176.26.176.43v2.383c0 .17-.059.312-.176.43a.562.562 0 01-.41.175zM6.057 9.046H3.655a.562.562 0 01-.41-.176.562.562 0 01-.176-.41V6.057a.53.53 0 01.176-.4.579.579 0 01.41-.166h2.402c.157 0 .293.058.41.176a.562.562 0 01.176.41v2.402a.53.53 0 01-.176.4.579.579 0 01-.41.167zM4.26 7.854h1.23V6.663H4.26v1.191zm11.387 1.192h-2.402a.562.562 0 01-.41-.176.562.562 0 01-.176-.41V6.057a.53.53 0 01.176-.4.578.578 0 01.41-.166h2.402c.156 0 .293.058.41.176a.562.562 0 01.176.41v2.402a.53.53 0 01-.176.4.579.579 0 01-.41.167zm-1.836-1.192h1.192V6.663H13.81v1.191zM8.46 13.811H1.253a.562.562 0 01-.41-.176.562.562 0 01-.176-.41v-2.383c0-.156.058-.293.176-.41a.562.562 0 01.41-.175H8.46c.156 0 .293.058.41.175a.562.562 0 01.176.41v2.403a.53.53 0 01-.176.4.579.579 0 01-.41.166zm-2.403 4.824H3.655a.562.562 0 01-.41-.175.584.584 0 01-.176-.43v-2.383c0-.17.059-.312.176-.43a.562.562 0 01.41-.175h2.402a.56.56 0 01.41.175c.118.118.176.26.176.43v2.383c0 .17-.058.312-.176.43a.562.562 0 01-.41.175zM4.26 17.444h1.23v-1.211H4.26v1.211zm13.79-3.633h-7.208a.562.562 0 01-.41-.176.562.562 0 01-.175-.41v-2.383c0-.156.058-.293.175-.41a.562.562 0 01.41-.175h7.207c.157 0 .294.058.41.175a.562.562 0 01.176.41v2.403a.53.53 0 01-.175.4.579.579 0 01-.41.166zm-2.403 4.824h-2.402a.562.562 0 01-.41-.175.584.584 0 01-.176-.43v-2.383c0-.17.058-.312.176-.43a.562.562 0 01.41-.175h2.402c.156 0 .293.058.41.175.117.118.176.26.176.43v2.383c0 .17-.059.312-.176.43a.562.562 0 01-.41.175zm-1.836-1.191h1.192v-1.211H13.81v1.211zM18.05 4.26h-7.207a.562.562 0 01-.41-.175.585.585 0 01-.175-.43V1.272c0-.17.058-.312.175-.43a.562.562 0 01.41-.175h7.207c.157 0 .294.058.41.175.118.118.176.26.176.43v2.383c0 .17-.058.312-.175.43a.562.562 0 01-.41.175z"
			></path>
		</svg>
	);
};

export default Exam;
