import React, { HTMLAttributes } from 'react';
import styles from './Typography.module.css';

export const SectionTitle: React.FC<HTMLAttributes<HTMLHeadingElement>> = ({
	className = '',
	children,
	...otherProps
}) => (
	<h2 {...otherProps} className={`${styles.sectionTitle} ${className}`}>
		{children}
	</h2>
);
