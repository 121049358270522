import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { Price } from '../../types';
import { formatCurrency } from '../../utils/currency';
import { config } from '../../utils/config';

import { useDeviceDetect } from '../../hooks/useDeviceDetect';

import { Radio } from '../../atoms/radio';
import { Text, BoldText, Subtitle } from '../../atoms/typography';
import Tooltip from '../../atoms/tooltip';
import { Alcohol, Coffee, Water, SoftDrink, Lunch, Snacks, Dining, Breakfast } from '../../atoms/icons';
import SpaceIncluded from '../../atoms/icons/features-icons/Space';

import styles from './ArrangementCell.module.css';

export const ArrangementCell: React.FC<ArrangementCellProps> = ({
	title,
	features,
	quote,
	featuresList,
	price,
	selected,
	onClick,
}) => {
	const [activeFeatureTooltip, setActiveFeatureTooltip] = useState<number | undefined>(undefined);

	const [isTooltipOpened, setTooltipOpened] = useState<boolean>(false);
	const [isIndexed, setIndex] = useState<number>();

	const device = useDeviceDetect();

	const { t } = useTranslation();

	const icons = {
		breakfast: <Breakfast />,
		lunch: <Lunch />,
		softdrinks: <SoftDrink />,
		alcoholic: <Alcohol />,
		water: <Water />,
		coffee_and_tea: <Coffee />,
		dinner: <Dining />,
		snacks: <Snacks />,
		space: <SpaceIncluded />,
	} as any;

	/**
	 * Hide currently visible tooltip when clicking anywhere
	 * in order to prevent two tooltips showing at the same time
	 */
	useEffect(() => {
		if (activeFeatureTooltip) {
			const handleBodyClick = () => {
				if (activeFeatureTooltip) {
					setActiveFeatureTooltip(undefined);
				}
			};
			document.body.addEventListener('click', handleBodyClick);
			return () => document.body.removeEventListener('click', handleBodyClick);
		}
	}, [activeFeatureTooltip]);

	const setFeatureTitle = (feature: string): string => {
		return featuresList[feature].map((product: any) => product.title).join(' · ');
	};

	return (
		<div role="button" className={`${styles.cell}`} onClick={onClick}>
			<div className={styles.flex}>
				<div className={styles.titleContainer}>
					<Subtitle className={styles.subtitle}>{title}</Subtitle>
				</div>
				<div className={styles.radioContainer}>
					<Radio type="check" selected={!!selected} />
				</div>
			</div>
			<div className={styles.spacer}>
				<ul className={styles.ul}>
					<li className={`${styles.li}`}>
						<div className={styles.flex}>
							<div className={styles.icon}>{icons['space']}</div>
							<Text>{t('space_included')}</Text>
						</div>
					</li>
					{featuresList &&
						Object.keys(featuresList).map((feature, index) => (
							<li
								key={index}
								className={`${styles.li} ${
									featuresList[feature].length > 1 ||
									(featuresList[feature].length === 1 && featuresList[feature][0].description)
										? styles.tooltip
										: ''
								}`}
							>
								<div className={styles.tooltipContainer}>
									{(device.isMobile ? !!isTooltipOpened : true) &&
										(featuresList[feature].length > 1 ||
											(featuresList[feature].length === 1 &&
												featuresList[feature][0].description)) && (
											<Tooltip
												listValue={featuresList[feature]}
												visible={activeFeatureTooltip === index + 1}
												title={''}
												description={''}
											/>
										)}
								</div>
								<div className={styles.flex}>
									<div className={styles.icon}>{feature ? icons[feature] : ''}</div>
									{!device.isMobile && (
										<Text
											className={styles.text}
											onMouseOver={(event) => {
												event.stopPropagation();
												if (featuresList[feature]) {
													setActiveFeatureTooltip(() => {
														if (!featuresList[feature]) {
															return undefined;
														}
														return index + 1;
													});
												}
											}}
											onMouseLeave={(event) => {
												event.stopPropagation();
												setActiveFeatureTooltip(() => {
													if (!featuresList[feature]) {
														return undefined;
													}
												});
											}}
										>
											{setFeatureTitle(feature)}
										</Text>
									)}

									{device.isMobile && (
										<Text
											className={styles.text}
											onClick={(event) => {
												event.stopPropagation();
												setIndex(index);

												isIndexed !== index && isTooltipOpened
													? setTooltipOpened(true)
													: setTooltipOpened(!isTooltipOpened);

												if (featuresList[feature]) {
													setActiveFeatureTooltip(() => {
														if (!featuresList[feature]) {
															return undefined;
														}
														return index + 1;
													});
												}
											}}
										>
											{setFeatureTitle(feature)}
										</Text>
									)}
								</div>
							</li>
						))}
				</ul>
			</div>
			{quote === 0 ? (
				<BoldText>{t('free')}</BoldText>
			) : (
				<>
					<BoldText>{formatCurrency(quote)}</BoldText>
					<Text>
						{`${hasDecimal(formatCurrency(quote)) ? ',- ' : ' '}${t('total').toLowerCase()} ${
							config.show_tax ? t('incl_tax') : t('excl_tax')
						}`}
					</Text>
				</>
			)}
		</div>
	);
};

interface ArrangementCellProps {
	title: string;
	features: {
		id?: number;
		title: string;
		description?: string | null;
		category?: string;
		component?: string;
	}[];
	featuresList?: any;
	price: Price;
	selected?: boolean;
	quote?: any;
	onClick?: () => void;
}

const hasDecimal = (num: any) => {
	return !!(num % 1);
};
