import React, { ButtonHTMLAttributes } from 'react';
import styles from './Button.module.css';
import { config } from '../../utils/config';

export const Button: React.FC<ButtonProps> = ({ className, title, ...otherProps }) => {
	let style: React.CSSProperties = {};

	if (config.color) {
		style.backgroundColor = config.color;
	}
	if (config.textColor) {
		style.color = config.textColor;
	}

	return (
		<button type="button" {...otherProps} className={`${styles.button} ${className || ''}`} style={style}>
			{title}
		</button>
	);
};

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {}
