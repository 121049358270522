import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDebounce } from 'use-debounce';
import { isValidPhoneNumber } from 'react-phone-number-input';

import styles from './BookScreen.module.css';
import { Space, Arrangement, Catering, Equipment, Invoice, Accomodation } from '../../types';
import { toLocaleLowerCaseNoun } from '../../utils/locale';
import { HttpService } from '../../utils/http';
import { getImageSource, AspectRatioOption } from '../../utils/image/helper';
import { colors } from '../../styles/colors';

import { PriceMatrix } from '../../molecules/price-matrix';
import { NavHeader } from '../../molecules/nav-header';

import { Button } from '../../atoms/button';
import { Text, Title, SectionTitle } from '../../atoms/typography';
import { Field } from '../../atoms/field';
import { Input } from '../../atoms/input';
import { Textarea } from '../../atoms/textarea';
import { Screen } from '../../atoms/screen';
import { showError } from '../../atoms/error';
import { Close, Check, Clock, Calendar, GroupSize } from '../../atoms/icons';

import { useFilters } from '../../hooks/useFilters';
import { PhoneInput } from '../../atoms/phone-input';

const httpService = new HttpService();
/** @see https://emailregex.com/ */
const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const BookScreen: React.FC<BookScreenProps> = ({
	onBack,
	onBookSuccess,
	space,
	arrangements,
	catering,
	equipment,
	accomodations,
}) => {
	const [data, setData] = useState({ comment: '', email: '', fullname: '', phone: '', company: '' });
	const [formErrors, setFormErrors] = useState({ email: '', fullname: '', phone: '', company: '' });
	const [invoice, setInvoice] = useState<Invoice>();
	const [fieldTouched, setFieldTouched] = useState({ email: false, fullname: false, phone: false, company: false });
	const [isLoading, setIsLoading] = useState(false);
	const { t, i18n } = useTranslation();
	const { filters } = useFilters();
	const [debouncedFormErrors] = useDebounce(formErrors, 1000);

	useEffect(() => {
		try {
			const previousData = JSON.parse(localStorage.getItem('venuesuite_booking_history') ?? '');

			if (previousData) {
				setData((state) => ({
					...state,
					email: previousData.email ?? '',
					fullname: previousData.fullname ?? '',
					company: previousData.company ?? '',
					phone: previousData.phone ?? '',
				}));
			}
		} catch (e) {}
	}, []);

	useEffect(() => {
		if (!invoice) {
			httpService
				.invoice({
					name: '',
					space: {
						id: space.id,
					},
					period: {
						start: filters.startDate.format('YYYY-MM-DD HH:mm:00'),
						end: filters.endDate.format('YYYY-MM-DD HH:mm:00'),
					},
					guests: filters.persons,
					type: filters.type,
					packages: [...arrangements],
					catering,
					equipment,
					hotel: accomodations,
				})
				.then((invoice) => {
					setInvoice(invoice);
				})
				.catch((error) => {
					console.debug(error);
					showError({ text: t('errors.network.fetch_invoice') });
					onBack();
				});
		}
	}, [arrangements, catering, equipment, filters, onBack, space, t, invoice, accomodations]);

	const createBooking = useCallback(async () => {
		setIsLoading(true);

		const organizationData = {
			organization: data.company,
			username: data.email,
			fullname: data.fullname,
			phone: data.phone,
			skip_email: true,
			lang: i18n.language,
		};
		const bookingData = {
			name: '',
			space: {
				id: space.id,
			},
			period: {
				start: filters.startDate.format('YYYY-MM-DD HH:mm:ss'),
				end: filters.endDate.format('YYYY-MM-DD HH:mm:ss'),
			},
			remarks: data.comment,
			guests: filters.persons,
			type: filters.type,
			packages: arrangements.map((arr) => ({ quantity: filters.persons, ...arr })),
			catering,
			equipment,
			hotel: accomodations,

			booker: {
				organization: data.email,
				company: data.company,
				email: data.email,
				fullname: data.fullname,
				phone: data.phone,
				lang: i18n.language,
			},
		};

		try {
			await httpService.createBooking(organizationData, bookingData);
			onBookSuccess(data.email, invoice!);
			localStorage.setItem(
				'venuesuite_booking_history',
				JSON.stringify({
					email: data.email,
					fullname: data.fullname,
					company: data.company,
					phone: data.phone,
				})
			);
		} catch (e) {
			showError({ text: t('errors.network.book') });
		} finally {
			setIsLoading(false);
		}
	}, [data, space, filters, arrangements, equipment, catering, onBookSuccess, t, i18n, accomodations]);

	useEffect(() => {
		const errors = { email: '', fullname: '', phone: '', company: '' };

		if (data.fullname.length < 2) {
			errors.fullname = t('errors.validation.name');
		}

		if (data.company.length < 2) {
			errors.company = t('errors.validation.company');
		}

		if (!data.email.length || !data.email.match(emailRegex)) {
			errors.email = t('errors.validation.email');
		}

		if (!data.phone.length || !isValidPhoneNumber(data.phone)) {
			errors.phone = t('errors.validation.telephone');
		}

		setFormErrors(errors);
	}, [data, t]);

	const imageHeight = 72;
	const imageRatio = 4 / 3; // Same as AspectRatioOption.WIDE()
	const imageWidth = imageHeight * imageRatio;
	let image;

	if (space.images.length) {
		image = getImageSource(
			space.images[0],
			AspectRatioOption.WIDE,
			Math.round(imageWidth * window.devicePixelRatio) * 2
		);
	}

	return (
		<Screen headerComponent={<NavHeader sticky onBack={onBack} />}>
			<div className={styles.container}>
				<div className={styles.title}>
					<Title>
						{t('summary', {
							spaceType: toLocaleLowerCaseNoun(t(`meeting_type.${filters.type}`), i18n.language),
						})}
					</Title>
				</div>
				<div className={styles.summary}>
					{image && (
						<img
							className={styles.image}
							alt={space.title}
							src={image}
							style={{ width: imageWidth, height: imageHeight }}
						/>
					)}
					<div className={styles.summaryText}>
						<Text>
							<GroupSize style={{ marginRight: 8 }} />
							{filters.persons} {t('persons_plural')}
						</Text>
						<Text>
							<Calendar style={{ marginRight: 8 }} />
							{dayjs(filters.startDate).format('DD MMM YYYY')}
						</Text>
						<Text>
							<Clock style={{ marginRight: 8 }} />
							{t('time_range', {
								startDate: filters.startDate.toDate(),
								endDate: filters.endDate.toDate(),
							})}
						</Text>
					</div>
				</div>

				{invoice && <PriceMatrix invoice={invoice} />}
			</div>
			<div className={styles.divider} />
			<div className={styles.container}>
				<div className={styles.form}>
					<Field label={t('comments_questions')} style={{ marginBottom: 24 }}>
						<Textarea onChange={(e) => setData({ ...data, comment: e.target.value })} />
					</Field>
					<Field
						label={t('contact_details')}
						help={t('contact_details_help')}
						error={fieldTouched.fullname && data.fullname ? formErrors.fullname : ''}
					>
						<Input
							value={data.fullname}
							postfix={
								(!formErrors.fullname && !debouncedFormErrors.fullname && (
									<Check color={colors.success} />
								)) ||
								(fieldTouched.fullname && data.fullname && <Close color={colors.error} />)
							}
							placeholder={t('full_name')}
							onBlur={() => setFieldTouched((state) => ({ ...state, fullname: true }))}
							onChange={(e) => setData({ ...data, fullname: e.target.value })}
						/>
					</Field>
					<Field error={fieldTouched.company && data.company ? formErrors.company : ''}>
						<Input
							value={data.company}
							postfix={
								(!formErrors.company && !debouncedFormErrors.company && (
									<Check color={colors.success} />
								)) ||
								(fieldTouched.company && data.company && <Close color={colors.error} />)
							}
							placeholder={t('company')}
							onBlur={() => setFieldTouched((state) => ({ ...state, company: true }))}
							onChange={(e) => setData({ ...data, company: e.target.value })}
						/>
					</Field>
					<Field error={fieldTouched.email && data.email ? formErrors.email : ''}>
						<Input
							value={data.email}
							postfix={
								(!formErrors.email && !debouncedFormErrors.email && <Check color={colors.success} />) ||
								(fieldTouched.email && data.email && <Close color={colors.error} />)
							}
							type="email"
							placeholder={t('email')}
							onBlur={() => setFieldTouched((state) => ({ ...state, email: true }))}
							onChange={(e) => setData({ ...data, email: e.target.value.replace(/\s+/g, '') })}
						/>
					</Field>
					<Field error={fieldTouched.phone && data.phone ? formErrors.phone : ''}>
						<PhoneInput
							value={data.phone}
							postfix={
								(!formErrors.phone && !debouncedFormErrors.phone && <Check color={colors.success} />) ||
								(fieldTouched.phone && data.phone && <Close color={colors.error} />)
							}
							placeholder={t('telephone')}
							onBlur={() => setFieldTouched((state) => ({ ...state, phone: true }))}
							onChange={(value) => setData({ ...data, phone: value })}
						/>
					</Field>
				</div>
				<Button
					className={styles.button}
					title={t('book')}
					disabled={Object.values(formErrors).join('').length > 0 || isLoading}
					onClick={createBooking}
				/>
				<SectionTitle className={styles.title}>{t('terms')}</SectionTitle>
				<div className={styles.spacer} />
				<Text className={styles.explanation}>{t('reservation_explanation')}</Text>
			</div>
		</Screen>
	);
};

interface BookScreenProps {
	onBack(): void;
	onBookSuccess(email: string, invoice: Invoice): void;
	space: Space;
	arrangements: Arrangement[];
	catering: Catering[];
	equipment: Equipment[];
	accomodations: Accomodation[];
}
