import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dayjs from 'dayjs';

import enTranslations from './en';
import nlTranslations from './nl';
import deTranslations from './de';
import enTranslationsForVMS from './vms/en';
import nlTranslationsForVMS from './vms/nl';
import deTranslationsForVMS from './vms/de';
import { config } from '../utils/config';

i18n.on('languageChanged', (lng) => dayjs.locale(lng));

i18n.use(initReactI18next).init({
	resources: {
		en: {
			translation: Object.assign(enTranslations, process.env.REACT_APP_ENV === 'vms' ? enTranslationsForVMS : {}),
		},
		nl: {
			translation: Object.assign(nlTranslations, process.env.REACT_APP_ENV === 'vms' ? nlTranslationsForVMS : {}),
		},
		de: {
			translation: Object.assign(deTranslations, process.env.REACT_APP_ENV === 'vms' ? deTranslationsForVMS : {}),
		},
	},
	lng: config.language || 'en',
	fallbackLng: 'en',
	interpolation: {
		escapeValue: false,
		format: (value, format) => {
			if (value instanceof Date) {
				return dayjs(value).format(format);
			}

			return value;
		},
	},
});

export default i18n;
