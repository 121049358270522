import React, { useMemo, MouseEvent } from 'react';
import { config } from '../../utils/config';
import { Team, User } from '../icons';
import styles from './NumberSelect.module.css';

export const NumberSelect: React.FC<NumberSelectProps> = ({ value, onChange, selected, max, isSingle, onClick }) => {
	const style = {
		color: config.color ? config.color : '',
		boxShadow: config.color ? `${'0 0 0 2px' + config.color}` : '',
	} as React.CSSProperties;
	const iconStyle = {
		color: config.color ? config.color : '',
	} as React.CSSProperties;

	const options = useMemo(() => {
		const items = Array(max + 1)
			.fill('')
			.map((_, index) => ({ label: String(index), value: String(index) }));

		items.push({ label: `${max}+`, value: String(max + 1) });
		return items;
	}, [max]);
	return (
		<div className={styles.wrapper}>
			<div className={`${styles.value} ${selected ? styles.selected : ''}`} style={selected ? style : {}}>
				{isSingle ? (
					<User className={styles.icon} style={selected ? iconStyle : {}} />
				) : (
					<Team className={styles.icon} style={selected ? iconStyle : {}} />
				)}
				{value}
			</div>

			<select
				onChange={(event) => onChange(event.target.value)}
				value={value}
				className={styles.select}
				data-testid="select"
				onClick={onClick}
			>
				{options.map((option) => (
					<option key={option.value} value={option.value}>
						{option.label}
					</option>
				))}
			</select>
		</div>
	);
};

interface NumberSelectProps {
	value: string;
	selected?: boolean;
	onChange(value: string): void;
	max: number;
	isSingle?: boolean | false;
	onClick?: (event: MouseEvent) => void;
}
