import React, { useEffect } from 'react';
import { Dayjs } from 'dayjs';
import { registerLocale } from 'react-datepicker';
import { de, nl } from 'date-fns/locale';

import { Input } from '../input';
import { Calendar } from '../icons';
import { Availability } from '../../types';
import { useModals } from '../../hooks/useModals';
import { TimeSlotModal } from './TimeSlotModal';

registerLocale('nl', nl);
registerLocale('de', de);

export const TimeSlotInput: React.FC<TimeSlotInputProps> = ({
	value,
	onChange,
	loading,
	borderless,
	availability,
	onMonthChange,
	onFocus,
	onCalendarOpen,
	onCalendarClose,
}) => {
	const { showModal, updateModal } = useModals();

	useEffect(() => {
		updateModal({
			key: 'time-slot-modal',
			element: (
				<TimeSlotModal
					value={value}
					onChange={onChange}
					loading={loading}
					onMonthChange={onMonthChange}
					onCloseModal={onCalendarClose}
					availability={availability}
				/>
			),
		});
	}, [availability, loading]);

	return (
		<Input
			readOnly
			icon={<Calendar />}
			borderless={borderless}
			value={`${value.startDate.format('D MMM HH:mm')} - ${value.endDate.format('HH:mm')}`}
			onClick={() => {
				onFocus?.();
				onCalendarOpen?.();
				showModal({
					key: 'time-slot-modal',
					element: (
						<TimeSlotModal
							value={value}
							onChange={onChange}
							onMonthChange={onMonthChange}
							onCloseModal={onCalendarClose}
							availability={availability}
							loading={loading}
						/>
					),
					closeWithEscape: true,
				});
			}}
		/>
	);
};

interface TimeSlotValue {
	startDate: Dayjs;
	endDate: Dayjs;
}

interface TimeSlotInputProps {
	value: TimeSlotValue;
	onChange(timeSlot: TimeSlotValue): void;
	onMonthChange?(month: Dayjs): void;
	onFocus?(): void;
	onCalendarOpen?(): void;
	onCalendarClose?(): void;
	loading?: boolean;
	borderless?: boolean;
	availability?: Availability[];
}
