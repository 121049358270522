import React from 'react';

import { config } from './utils/config';

const CSSVariables = () => (
	<style>
		{`
			:root {
				--primary-color: ${config.color};
				--text-color: ${config.textColor};
			}
		`}
	</style>
);

export default CSSVariables;
