import React from 'react';

export const Party: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 32 32" {...props}>
			<path
				fill="currentColor"
				d="M2.573 1.627H14.08c.53 0 .96.43.96.96V14.08c0 .53-.43.96-.96.96H2.573a.96.96 0 01-.96-.96V2.587c0-.53.43-.96.96-.96zM6.4 16.96H2.573a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96H6.4c.53 0 .96-.43.96-.96V17.92a.96.96 0 00-.96-.96zm-.96 3.827H3.533V18.88H5.44zm8.64 3.84h-3.827a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96h3.827c.53 0 .96-.43.96-.96v-3.827a.96.96 0 00-.96-.96zm-.96 3.826h-1.907v-1.907h1.907zm8.627-26.826H17.92a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96h3.827a.96.96 0 00.92-.959V2.586a.96.96 0 00-.918-.959zm-.96 3.826H18.88V3.546h1.907zm.96 19.174H17.92a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96h3.827c.53 0 .96-.43.96-.96v-3.827a.96.96 0 00-.96-.96zm-.96 3.826H18.88v-1.907h1.907zm8.64-19.12H25.6a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96h3.827c.53 0 .96-.43.96-.96v-3.867a.96.96 0 00-.959-.92zm-.96 3.827H26.56v-1.947h1.907zm.96 3.8H25.6a.96.96 0 00-.96.96v3.827c0 .53.43.96.96.96h3.827c.53 0 .96-.43.96-.96V17.92a.96.96 0 00-.96-.96zm-.96 3.827H26.56V18.88h1.907z"
			></path>
		</svg>
	);
};

export default Party;
