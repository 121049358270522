export default {
	book: 'Optie aanvragen',
	terms: 'Wat gebeurt er als je een optie aanvraagt?',
	reservation_explanation: `1. 
Je ontvangt direct een bevesting van de aanvraag, inclusief offerte.

2. 
Wij zullen zo snel mogelijk op deze aanvraag reageren (meestal binnen een paar uur).

3.
Vervolgens kan je de optie definitief bevestigen of kosteloos annuleren.`,
	success: {
		title: 'Bedankt voor jouw aanvraag!',
		description: 'Wij hebben een bevestiging van de aanvraag naar <1>{{email}}</1> gestuurd.',
		next_step: 'Wij zullen zo snel mogelijk op deze aanvraag reageren (meestal binnen een paar uur).',
	},
};
