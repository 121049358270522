import React from 'react';

export const Dining: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="19" height="19" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M17.333 11.253c-0.53 0-0.96 0.43-0.96 0.96s0.43 0.96 0.96 0.96v0c1.88 0.001 3.532 0.975 4.481 2.446l0.013 0.021c0.175 0.181 0.419 0.293 0.69 0.293 0.53 0 0.96-0.43 0.96-0.96 0-0.109-0.018-0.215-0.052-0.313l0.002 0.007c-1.285-2.054-3.53-3.402-6.092-3.413h-0.002zM2.787 20.667h26.787c0.001 0 0.003 0 0.005 0 0.53 0 0.96-0.43 0.96-0.96 0-0.033-0.002-0.065-0.005-0.097l0 0.004c-0.592-6.374-5.284-11.501-11.388-12.744l-0.092-0.016c0-1.591-1.289-2.88-2.88-2.88s-2.88 1.289-2.88 2.88v0c-6.19 1.264-10.876 6.389-11.462 12.703l-0.004 0.057c-0.003 0.028-0.005 0.060-0.005 0.093 0 0.53 0.43 0.96 0.96 0.96 0.002 0 0.003 0 0.005 0h-0zM16.12 8.48c0.017-0 0.036-0 0.056-0 6.1 0 11.173 4.395 12.226 10.191l0.011 0.076h-24.52c1.064-5.872 6.138-10.267 12.238-10.267 0.020 0 0.039 0 0.059 0h-0.003zM30.213 24.52h-28.427c-0.53 0-0.96 0.43-0.96 0.96s0.43 0.96 0.96 0.96v0h28.427c0.53 0 0.96-0.43 0.96-0.96s-0.43-0.96-0.96-0.96v0z"
				fill="currentColor"
			/>
		</svg>
	);
};

export default Dining;
