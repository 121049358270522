import React from 'react';

export const CaretDown: React.FC<React.SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg width="8" height="8" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path fill="#707D83" d="M0.666748 0.666664L4.00008 4L7.33342 0.666664H0.666748Z" />
		</svg>
	);
};

export default CaretDown;
