import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { Screen } from '../../atoms/screen';
import styles from './SuccessScreen.module.css';
import { Title, Text, EmphasizedText } from '../../atoms/typography';
import { CheckCircle } from '../../atoms/icons';
import { Button } from '../../atoms/button';
import { SuccessScreenForVMS } from './SuccessScreenForVMS';

export const SuccessScreen: React.FC<SuccessScreenProps> = ({ onClose, email }) => {
	const { t } = useTranslation();
	const checkmarks = t('success.checkmark', { joinArrays: '+' }).split('+');

	return process.env.REACT_APP_ENV === 'vms' ? (
		<SuccessScreenForVMS onClose={onClose} email={email} />
	) : (
		<Screen
			headerComponent={<div className={styles.header} />}
			footerComponent={<Button title={t('done')} onClick={onClose} />}
		>
			<div className={styles.container}>
				<Title className={styles.title}>{t('success.title')}</Title>
				<div className={styles.explanation}>
					<Text>
						<Trans i18nKey="success.description">
							We have sent a confirmation to <EmphasizedText>{{ email }}</EmphasizedText> with a link to
							activate your account to:
						</Trans>
					</Text>
				</div>
				{checkmarks.map((checkmark, index) => (
					<div key={index} className={styles.checkmarkRow}>
						<div className={styles.checkmark}>
							<CheckCircle height={18} width={18} />
						</div>
						<Text className={styles.rowText}>{checkmark}</Text>
					</div>
				))}
				<div className={styles.email}>
					<Text>{t('success.check_spam')}</Text>
				</div>
			</div>
		</Screen>
	);
};

interface SuccessScreenProps {
	onClose(): void;
	email: string;
}
